import { createContext, useEffect, useReducer, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
//import Amplify, { API, Auth, Hub } from 'aws-amplify';
//import config from '../configs/aws-config';
import { API, Auth, Hub } from 'aws-amplify';
import { User, OnboardingStep, Role } from '../types/user';
import {
  CognitoHostedUIIdentityProvider,
  CognitoUser,
} from '@aws-amplify/auth/lib/types';
import log from 'loglevel';
import { logError } from 'src/lib/errorLib';
import { gtm } from 'src/lib/gtm';
import { segment } from 'src/lib/segment';
import { useGetUserMutation } from 'src/hooks/user-hooks';
import { useCreateUsernameMutation } from 'src/hooks/account-hooks';
import { useLocalStorage } from 'src/lib/hooksLib';
import { useGetReferrerMutation } from 'src/hooks/referrer-hooks';
import { useAddTrustedAccountMutation } from 'src/hooks/trusted-accounts-hooks';
import { useCreateUserMutation } from 'src/hooks/user-hooks';
//import { sendExtInstalledCheckMessage } from '../lib/send-auth-message';

export const EXT_INSTALLED_KEY = 'counton-ext-installed';
export const SAFARI_EXT_INSTALLED_KEY = 'counton-safari-ext-installed';
//Amplify.configure(config);

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  redirectURL: string;
  user: User | null;
}

export interface AuthContextValue extends State {
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (
    email: string,
    password: string,
    firstname: string
  ) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
  refreshUser: (user?: User) => void;
  signInWithGoogle: (register?: boolean, redirectURL?: string) => Promise<void>;
  signInWithFb: (register?: boolean, redirectURL?: string) => Promise<void>;
  isExtInstalled: boolean;
  isSafariExtInstalled: boolean;
  updateExtInstalled: (boolean) => void;
  updateSafariExtInstalled: (boolean) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    redirectURL: string;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type VerifyCodeAction = {
  type: 'VERIFY_CODE';
};

type ResendCodeAction = {
  type: 'RESEND_CODE';
};
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY';
};

type PasswordResetAction = {
  type: 'PASSWORD_RESET';
};

type SignInWithGoogleAction = {
  type: 'SIGNINWITHGOOGLE';
  payload: {
    register: boolean;
  };
};

type SignInWithFbAction = {
  type: 'SIGNINWITHFB';
  payload: {
    register: boolean;
  };
};

type RefreshUserAction = {
  type: 'REFRESHUSER';
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction
  | SignInWithGoogleAction
  | SignInWithFbAction
  | RefreshUserAction;

const initialState: State = {
  isAuthenticated: false,
  redirectURL: null,
  isInitialized: false,
  user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, redirectURL, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      redirectURL,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;
    gtm.push({ event: 'login', method: 'email' });
    segment.track('User Logged In', { method: 'email' });
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: InitializeAction): State => {
    const { user } = action.payload;
    gtm.push({ event: 'sign_up', method: 'email' });
    segment.track('User Signed Up', { method: 'email' });
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  VERIFY_CODE: (state: State): State => ({ ...state }),
  RESEND_CODE: (state: State): State => ({ ...state }),
  PASSWORD_RECOVERY: (state: State): State => ({ ...state }),
  PASSWORD_RESET: (state: State): State => ({ ...state }),
  REFRESHUSER: (state: State, action: RefreshUserAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      user,
    };
  },
  SIGNINWITHGOOGLE: (state: State, action: SignInWithGoogleAction): State => {
    const { register } = action.payload;

    if (register) {
      log.trace(`Signing up with google`);
    } else {
      gtm.push({ event: 'login', method: 'google' });
      segment.track('User Logged In', { method: 'google' });
    }
    return {
      ...state,
    };
  },
  SIGNINWITHFB: (state: State, action: SignInWithFbAction): State => {
    const { register } = action.payload;
    if (register) {
      log.trace(`Signing up with facebook`);
    } else {
      gtm.push({ event: 'login', method: 'facebook' });
      segment.track('User Logged In', { method: 'facebook' });
    }
    return {
      ...state,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithFb: () => Promise.resolve(),
  refreshUser: () => Promise.resolve(),
  isExtInstalled: false,
  isSafariExtInstalled: false,
  updateExtInstalled: () => {},
  updateSafariExtInstalled: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const getProfileMutation = useGetUserMutation();
  const createUsernameMutation = useCreateUsernameMutation();
  const userMutation = useCreateUserMutation();
  const addUserMutation = useAddTrustedAccountMutation();
  const [referrer] = useLocalStorage('referrer', null);
  const referrerMutation = useGetReferrerMutation(referrer);
  const [isExtInstalled, setIsExtInstalled] = useState(false);
  const [isSafariExtInstalled, setIsSafariExtInstalled] = useState(false);

  // Once when loading page, check if extension is installed
  useEffect(() => {
    // Check for presence of 'ext-installed' in local stroage
    const extInstalled = localStorage.getItem(EXT_INSTALLED_KEY);
    if (extInstalled) {
      setIsExtInstalled(true);
    } else {
      // This code seemed to be inconsistent. See
      // https://countongroup.slack.com/archives/C04DQDBTKC0/p1682442029778569
      // So we're going to rely on the extension-installed paged alone for now.
      // If not in local storage, try to communicate with it directly
      //sendExtInstalledCheckMessage(() => {
      //  updateExtInstalled(true);
      //});
    }
  }, []);

  useEffect(() => {
    const safariExtInstalled = localStorage.getItem(SAFARI_EXT_INSTALLED_KEY);
    if (safariExtInstalled) {
      setIsSafariExtInstalled(true);
    } else {
      // Similar to above - if not in local storage - do we check the extension?
      // If needed put that here
    }
  }, []);

  // Update the value and store in local storage
  const updateExtInstalled = (newVal: boolean) => {
    log.debug('updateExtInstalled', newVal);
    if (newVal) {
      localStorage.setItem(
        EXT_INSTALLED_KEY,
        JSON.stringify({ date: Date.now() })
      );
      log.debug('updateExtInstalled - localstorage key set');
    } else {
      localStorage.removeItem(EXT_INSTALLED_KEY);
      log.debug('updateExtInstalled - localstorage key removed');
    }
    setIsExtInstalled(newVal);
  };

  const updateSafariExtInstalled = (newVal: boolean) => {
    log.debug('updateSafariExtInstalled', newVal);
    if (newVal) {
      localStorage.setItem(
        SAFARI_EXT_INSTALLED_KEY,
        JSON.stringify({ date: Date.now() })
      );
      log.debug('updateSafariExtInstalled - localstorage key set');
    } else {
      localStorage.removeItem(SAFARI_EXT_INSTALLED_KEY);
      log.debug('updateSafariExtInstalled - localstorage key removed');
    }
    setIsSafariExtInstalled(newVal);
  };

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        let redirectURL: string;
        Hub.listen('auth', ({ payload: { event, data } }) => {
          switch (event) {
            case 'customOAuthState':
              redirectURL = JSON.parse(data)['redirectURL'];
          }
        });
        const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
        const user_id = cognitoUser.username;
        const email = cognitoUser.attributes.email;
        const email_verified = cognitoUser.attributes.email_verified;
        const is_google = user_id.startsWith('google_');
        const is_fb = user_id.startsWith('facebook_');
        let user_name = cognitoUser.attributes['custom:user_name'] || '';

        /*
          If the email is not verified, short-circuit to Verify Onboarding step if they select
          the route /profile
        */
        if (!email_verified && !(is_google || is_fb)) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              redirectURL: redirectURL,
              user: {
                user_id: null,
                user_name: null,
                email: email,
                role: Role.VIEWER,
                onboarding: OnboardingStep.VERIFY,
                active_account: null,
              },
            },
          });
        } else {
          try {
            let user: User = await API.get('trustable', '/profile', {});
            if (!user.active_account && (is_google || is_fb)) {
              // If the user is a google or fb user, and they don't have an active account, we need to create one
              // This is the case when a user logs in via fb or google the first time
              segment.identify(user.user_id, {
                username: user.user_name,
                email: user.email,
                role: user.role,
              });

              gtm.push({
                event: 'sign_up',
                method: is_google ? 'google' : 'facebook',
              });
              segment.track('User Signed Up', {
                method: is_google ? 'google' : 'facebook',
              });

              user_name = cognitoUser.attributes.given_name;
              user_name = await createUsernameMutation.mutateAsync(user_name);
              let recommender = null;
              if (referrer) {
                recommender = await referrerMutation.mutateAsync();
              }
              log.debug('recommender', recommender);
              const body = {
                user: {
                  user_id: user.user_id,
                  user_name: user_name,
                  email: email,
                  role: Role.VIEWER,
                  onboarding: OnboardingStep.VALUES,
                },
                referrer: recommender?.account_id || null,
                preview: recommender ? 'beta' : null,
                display_name: cognitoUser.attributes.given_name || '',
              };
              const updateduser: User = await userMutation.mutateAsync(body);
              if (recommender) {
                await addUserMutation.mutateAsync(recommender);
              }
              user = updateduser;
            }
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                redirectURL: redirectURL,
                user: {
                  user_id: user.user_id || null,
                  user_name: user.user_name || user_name,
                  email: user.email || email,
                  role: user.role || Role.VIEWER,
                  onboarding: user.onboarding || OnboardingStep.VALUES,
                  active_account: user.active_account || null,
                  active_account_id: user.active_account_id || null,
                  trusted_account_display_names:
                    user.trusted_account_display_names || null,
                  accounts: user.accounts || null,
                },
              },
            });
          } catch (error) {
            /* 
          In case we want to do something if the user is not found
          */
            logError(error, { message: 'unable to fetch user' });
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                redirectURL: redirectURL,
                user: {
                  user_id: null,
                  user_name: null,
                  email: null,
                  role: Role.VIEWER,
                  onboarding: OnboardingStep.SIGNUP,
                  active_account: null,
                  active_account_id: null,
                  trusted_account_display_names: null,
                  accounts: null,
                },
              },
            });
          }
        }
      } catch (error) {
        if (error !== 'The user is not authenticated') logError(error);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            redirectURL: null,
            user: {
              user_id: null,
              user_name: null,
              email: null,
              role: Role.VIEWER,
              onboarding: OnboardingStep.SIGNUP,
              active_account: null,
              active_account_id: null,
              trusted_account_display_names: null,
              accounts: null,
            },
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    try {
      const cognitoUser = await Auth.signIn(email, password);
      try {
        const user: User = await API.get('trustable', '/profile', {});
        dispatch({
          type: 'LOGIN',
          payload: {
            user: {
              user_id: user.user_id,
              user_name: user.user_name,
              email: user.email,
              role: user.role || Role.VIEWER,
              onboarding: user.onboarding,
              active_account: user.active_account || null,
              active_account_id: user.active_account_id || null,
              trusted_account_display_names:
                user.trusted_account_display_names || null,
              accounts: user.accounts || null,
            },
          },
        });
      } catch (error) {
        // This is the case when they have made an account and verified it with
        // but there is no username associated with it. So no backend user created in count on db.
        if (cognitoUser.attributes.email_verified) {
          dispatch({
            type: 'LOGIN',
            payload: {
              user: {
                user_id: null,
                user_name: null,
                email: cognitoUser.attributes.email,
                role: Role.VIEWER,
                onboarding: OnboardingStep.USERNAME,
              },
            },
          });
        } else {
          // This is the case when they have made an account with count on but have
          // not finished the verification page
          dispatch({
            type: 'LOGIN',
            payload: {
              user: {
                user_id: null,
                user_name: null,
                email: cognitoUser.attributes.email,
                role: Role.VIEWER,
                onboarding: OnboardingStep.VERIFY,
              },
            },
          });
        }
      }
    } catch (e) {
      // Throw the error so login component can handle error message
      throw e;
    }
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    segment.clearState();
    dispatch({
      type: 'LOGOUT',
    });
  };

  const register = async (
    email: string,
    password: string,
    firstname: string
  ): Promise<void> => {
    try {
      // Generate username before signing up in cognito
      const user_name = await createUsernameMutation.mutateAsync(firstname);
      await Auth.signUp({
        username: email,
        password,
        attributes: { email, 'custom:user_name': user_name },
        autoSignIn: {
          enabled: true,
        },
      });
      const user: User = {
        user_id: null,
        user_name: user_name,
        email: email,
        role: Role.VIEWER,
        onboarding: OnboardingStep.VERIFY,
      };

      dispatch({
        type: 'REGISTER',
        payload: {
          user: {
            user_id: null, // Set after username has been selected
            user_name: user.user_name, // set after username has been selected
            email: user.email,
            role: user.role || Role.VIEWER, // default
            onboarding: user.onboarding, //default
            firstname: firstname, // used to pass to verify page to set display name
          },
        },
      });
    } catch (e) {
      // Throwing the error so component can handle it.
      throw e;
    }
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE',
    });
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE',
    });
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY',
    });
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET',
    });
  };

  const signInWithGoogle = async (
    register: boolean = false,
    redirectURL: string = ''
  ): Promise<void> => {
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
        customState: JSON.stringify({ redirectURL: redirectURL }),
      });
      dispatch({
        type: 'SIGNINWITHGOOGLE',
        payload: {
          register,
        },
      });
    } catch (error) {
      logError(error);
    }
  };

  const refreshUser = async (user?: User): Promise<void> => {
    if (!user) {
      user = await getProfileMutation.mutateAsync();
    }
    dispatch({
      type: 'REFRESHUSER',
      payload: {
        user: user,
      },
    });
  };

  const signInWithFb = async (
    register: boolean = false,
    redirectURL: string = ''
  ): Promise<void> => {
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
        customState: JSON.stringify({ redirectURL: redirectURL }),
      });
      dispatch({
        type: 'SIGNINWITHFB',
        payload: {
          register,
        },
      });
    } catch (error) {
      logError(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        signInWithGoogle,
        signInWithFb,
        refreshUser,
        isExtInstalled,
        isSafariExtInstalled,
        updateExtInstalled,
        updateSafariExtInstalled,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
