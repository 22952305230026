import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Outlet, useLocation } from 'react-router-dom';
import { useSegment } from 'src/contexts/segment-context';
import { useAuth } from '../../hooks/use-auth';
import { SplashScreen } from '../../components/splash-screen';

const OnboardingLayout = () => {
  const [query, setQuery] = useState('');
  const location = useLocation();
  const segment = useSegment();
  const { isInitialized } = useAuth();

  useEffect(() => {
    segment.page('Onboarding', location.pathname, { layout: 'onboarding' });
  }, [location.pathname, segment]);

  if (!isInitialized) return <SplashScreen />;

  return (
    <Box minHeight="100vh" sx={{ backgroundColor: 'pastel.dark' }}>
      <Box>
        <Outlet context={[query, setQuery]} />
      </Box>
    </Box>
  );
};

export default OnboardingLayout;
