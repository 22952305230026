import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import log from 'loglevel';

import { useReferrer } from 'src/lib/hooksLib';
import { useGetPublicProfile } from 'src/hooks/account-hooks';
import { useGetRatingSystems } from 'src/hooks/rating-system-hooks';
import { useGetTrustedAccountsQuery } from 'src/hooks/trusted-accounts-hooks';

import { LoadingSpinner } from 'src/components/loading-spinner';
import Biocard from './components/Biocard';
import BasicTabs from './components/BasicTabs';
import Container from 'src/components/Container';
import SectionContainer from 'src/components/SectionContainer';

interface PublicProfileProps {
  ext?: boolean;
  iniTab?: 'products' | 'about';
}

/**
 * The "ext" flag means this profile is being viewed via the "/r" referral route
 * so the account_name of this page should be saved as the referring account in case
 * this person creates an account
 *
 * @param {*} param0
 * @returns
 */

export default function PublicProfile({
  ext = false,
  iniTab = 'products',
}: PublicProfileProps) {
  const { account_name } = useParams();
  const [, setReferrer] = useReferrer();
  const {
    data: account,
    isLoading,
    isError,
    error,
  } = useGetPublicProfile(account_name);
  const { data: trustedAccounts } = useGetTrustedAccountsQuery();
  const [isAccountTrusted, setIsAccountTrusted] = useState(null);

  // This is here solely to pre-cache these for when the search results are
  // returned later
  useGetRatingSystems();

  useEffect(() => {
    document.title = account
      ? `${account.display_name} (@${account.account_name}) | CountOn`
      : 'CountOn';
  }, [account]);

  useEffect(() => {
    if (!trustedAccounts || !account) {
      return;
    }
    const doesContainAccount = trustedAccounts.some(
      (trustedAccount) =>
        trustedAccount.account_name.toLowerCase() ===
        account.account_name.toLowerCase()
    );
    setIsAccountTrusted(doesContainAccount);
  }, [trustedAccounts, account]);

  // Save the account_name in local storage only if the ext flag is set AND the
  // account actually exists
  const accountName = account?.account_name;
  useEffect(() => {
    if (!accountName || !ext) return;
    log.debug(`Saving referrer account "${accountName}" in local storage`);
    setReferrer(accountName);
  }, [accountName, ext, setReferrer]);

  if (isLoading) return <LoadingSpinner />;

  if (isError) return <Container>Error: {error.message}</Container>;

  if (!account) {
    return (
      <Container>
        <Typography>Sorry, there is no account with that ID.</Typography>
      </Container>
    );
  }

  const isEmptyProfile = account.num_reviews === 0;

  const EmptyProfileSection = (
    <SectionContainer>
      <Box
        width={{ sm: '100%', md: 850 }}
        minHeight={{ sm: 130, md: 150 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: 'pastel.purple',
          borderRadius: 1,
        }}
      >
        <Typography
          fontSize="22px"
          fontWeight={600}
          textAlign="center"
          maxWidth={600}
          margin={1}
          fontStyle="italic"
        >
          This profile is coming soon.{' '}
          {isAccountTrusted
            ? "We'll notify you as soon as recommendations are available."
            : `Count on @${account.account_name} to get
          notified when recommendations are live!`}
        </Typography>
      </Box>
    </SectionContainer>
  );

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Biocard account={account} />
        {isEmptyProfile ? (
          EmptyProfileSection
        ) : (
          <BasicTabs account={account} iniTab={iniTab} />
        )}
      </Stack>
      {/*<StickyFooter account={account} />*/}
    </Box>
  );
}
