import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import log from 'loglevel';
import { logError } from 'src/lib/errorLib';
import countOnLogo from 'src/assets/logos/CountOn_primary.png';
import { useContext, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../../hooks/use-auth';
import { OnboardingStep } from 'src/types/user';
import { OnboardingContext } from '../../../contexts/onboarding-context';
import googlePNG from 'src/assets/social-icons/google.png';
import facebookSVG from 'src/assets/social-icons/facebook.svg';
import { Link, useLocation } from 'react-router-dom';
import { useSegment } from '../../../contexts/segment-context';
import { isWebView } from '../../../lib/contextLib';
import { useLocalStorage } from '../../../lib/hooksLib';

const SignupPage = () => {
  const { register, signInWithGoogle, signInWithFb } = useAuth();
  const theme = useTheme();
  const location = useLocation();
  const segment = useSegment();
  const { nextStep } = useContext(OnboardingContext);
  const heading = 'Welcome to';
  const sub_heading = `Create a free account to get access to trusted recommendations wherever you shop.`;
  const width = { xs: '75%', md: '40%' };
  const from = location.state?.from || '/';

  const handleSignUpWithGoogle = () => {
    segment.track('User Clicked Signup', { method: 'google' });
    signInWithGoogle(true);
  };

  const handleSignUpWithFacebook = () => {
    segment.track('User Clicked Signup', { method: 'facebook' });
    signInWithFb(true);
  };

  useEffect(() => {
    document.title = 'CountOn Sign Up';
  }, []);

  // After signing up, we should try to share auth state with the browser
  // extension.
  const [syncAuth, setSyncAuth] = useLocalStorage('should-sync-auth', null);
  useEffect(() => {
    if (!syncAuth) setSyncAuth(true);
  }, [setSyncAuth, syncAuth]);

  const formik = useFormik({
    initialValues: {
      firstname: '',
      email: '',
      password: '',
      confirmPassword: '',
      submit: null,
    },
    validationSchema: Yup.object({
      email: yup
        .string()
        .trim()
        .email()
        .required('You must provide a valid email address'),
      password: yup
        .string()
        .required('Please enter a password')
        .max(100, 'Passwords must be less than 100 characters')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\-_])[A-Za-z\d@$!%*#?&\-_]{8,}$/,
          'Must contain at least 8 characters, with a mix of uppercase, lowercase, numbers, and special characters'
        ),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: async (values): Promise<void> => {
      try {
        log.debug('submitting', values);
        segment.track('User Clicked Signup', {
          method: 'email',
          email: values.email,
          first_name: values.firstname.trim(),
        });
        await register(values.email, values.password, values.firstname.trim());
        nextStep(OnboardingStep.VERIFY);
      } catch (err) {
        logError(err);
        toast.error(err.message);
      }
    },
  });
  const googleIcon = (
    <img alt="google" src={googlePNG} height={25} width={27} />
  );
  const fbIcon = <img alt="fb" src={facebookSVG} height={25} width={27} />;

  return (
    <Box
      sx={{
        backgroundColor: 'pastel.light',
        height: '125vh',
        minHeight: '125vh',
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: 1,
          pt: { xs: '50px', sm: '90px' },
        }}
      >
        <Typography
          fontSize={{ xs: '28px', sm: '28px' }}
          lineHeight={'38px'}
          fontStyle={'500'}
          textAlign="center"
          gutterBottom
        >
          {heading}
        </Typography>
        <Box mb="3px">
          <img width="146px" src={countOnLogo} alt="CountOn Logo" />
        </Box>
      </Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 1.75, sm: 2 }}
        sx={{ width: width, mx: 'auto', mb: 1 }}
      >
        <Box>
          <Typography
            fontWeight="400"
            fontSize={{ xs: '16px', sm: '16px' }}
            lineHeight={'22px'}
            fontFamily="Poppins"
            textAlign="center"
            mb={{ xs: '20px', sm: '40px' }}
          >
            {sub_heading}
          </Typography>
        </Box>
        {!isWebView && (
          <Button
            variant="outlined"
            disableFocusRipple
            fullWidth
            sx={{
              borderColor: theme.palette.greyCustom.light,
              boxShadow: '1',
              backgroundColor: theme.palette.white.main,
              '&:hover': {
                backgroundColor: theme.palette.white.main,
              },
            }}
            size="large"
            onClick={handleSignUpWithGoogle}
            startIcon={googleIcon}
          >
            <Typography color={theme.palette.greyCustom.dark}>
              Sign up with Google
            </Typography>
          </Button>
        )}

        <Button
          variant="outlined"
          fullWidth
          sx={{
            borderColor: theme.palette.greyCustom.light,
            backgroundColor: theme.palette.white.main,
            boxShadow: '1',
            '&:hover': {
              backgroundColor: theme.palette.white.main,
            },
          }}
          onClick={handleSignUpWithFacebook}
          startIcon={fbIcon}
        >
          <Typography color={theme.palette.greyCustom.dark}>
            Sign Up with Facebook
          </Typography>
        </Button>
        <Divider sx={{ width: 1 }}>Or</Divider>
      </Stack>

      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 1.75, sm: 2 }}
        >
          <TextField
            label="First Name *"
            variant="outlined"
            id="firstname"
            sx={{
              width: width,
              backgroundColor: theme.palette.white.main,
            }}
            {...formik.getFieldProps('firstname')}
            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
            helperText={formik.touched.firstname && formik.errors.firstname}
          />
          <TextField
            label="Email *"
            variant="outlined"
            id="email"
            type="email"
            sx={{
              width: width,
              backgroundColor: theme.palette.white.main,
            }}
            inputProps={{ autoCapitalize: 'none' }}
            {...formik.getFieldProps('email')}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            label="Password *"
            variant="outlined"
            type="password"
            sx={{
              width: width,
              backgroundColor: theme.palette.white.main,
            }}
            {...formik.getFieldProps('password')}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            label="Confirm *"
            variant="outlined"
            type="password"
            id="confirmPassword"
            sx={{
              width: width,
              backgroundColor: theme.palette.white.main,
            }}
            {...formik.getFieldProps('confirmPassword')}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <Stack
            direction={{ xs: 'column-reverse', sm: 'row' }}
            justifyContent={{ xs: 'center', sm: 'space-between' }}
            alignItems="center"
            sx={{
              width: width,
            }}
          >
            <Box marginBottom={{ xs: 1, sm: 0 }} marginTop={{ xs: 2, sm: 0 }}>
              <Typography textAlign="left" variant={'subtitle2'}>
                Already have an account?{' '}
                <Link
                  color="primary"
                  to="/login"
                  state={{ from: from }}
                  replace
                  style={{ textDecoration: 'none' }}
                >
                  Log in.
                </Link>
              </Typography>
              {formik.isSubmitting && (
                <Typography variant={'subtitle2'}>
                  (Please be patient, this might take up to a minute)
                </Typography>
              )}
            </Box>
            <LoadingButton
              size={'large'}
              variant={'contained'}
              type={'submit'}
              loading={formik.isSubmitting}
              sx={{ width: { xs: '100%', sm: '25%' }, mt: { xs: 2, sm: 0 } }}
            >
              Sign up
            </LoadingButton>
          </Stack>
          {isWebView && (
            <Box sx={{ px: 2, pb: 3 }}>
              <Typography align="center">
                Looking for Google login? Open this page in your phone's web
                browser to access!
              </Typography>
            </Box>
          )}
        </Stack>
      </form>
    </Box>
  );
};
export default SignupPage;
