import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import log from 'loglevel';
import { logError } from 'src/lib/errorLib';
import { useAuth } from 'src/hooks/use-auth';
import { OnboardingContext } from 'src/contexts/onboarding-context';
import { OnboardingStep } from 'src/types/user';
import { Hub, Auth } from 'aws-amplify';

import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useCreateUserMutation } from 'src/hooks/user-hooks';
import { User } from 'src/types/user';
import { useLocalStorage } from '../../../lib/hooksLib';
import { useGetRefferer } from '../../../hooks/referrer-hooks';
import { useAddTrustedAccountMutation } from '../../../hooks/trusted-accounts-hooks';
import * as Sentry from '@sentry/react';
import { useSegment } from '../../../contexts/segment-context';

// This function waits for the user to get signed in after verifying the code
async function waitForSignIn() {
  return new Promise((resolve, reject) => {
    const ms = 10000;
    const timer = setTimeout(() => {
      // Reject the promise with a timeout error if the user is not signed in after 10 seconds
      Auth.currentAuthenticatedUser()
        .then((user) => {
          console.log(user);
          resolve(user);
        })
        .catch((e) => {
          Sentry.captureException(
            'Operation timed out waiting for autosign in.'
          );
          reject(new Error(`Operation timed out waiting after ${ms}ms`));
        });
    }, ms);

    const listener = Hub.listen('auth', ({ payload: { event, data } }) => {
      log.debug('listening for data');
      switch (event) {
        case 'autoSignIn':
          log.debug(data);
          clearTimeout(timer);
          resolve(data);
          Hub.remove('auth', listener);
          log.debug('removed listener');
          break;
        case 'autoSignIn_failure':
          log.error('Error signing in', data);
          Sentry.captureException('Error signing in after verification.');
          clearTimeout(timer);
          reject(data);
          Hub.remove('auth', listener);
          log.debug('removed listener');
          break;
      }
    });
  });
}

const VerifyPage = () => {
  const theme = useTheme();
  const userMutation = useCreateUserMutation();
  const addUserMutation = useAddTrustedAccountMutation();
  const segment = useSegment();
  const { user, verifyCode, resendCode, refreshUser } = useAuth();
  const { nextStep } = useContext(OnboardingContext);

  const [referrer] = useLocalStorage('referrer', null);
  const referrerQuery = useGetRefferer(referrer);
  if (referrer && referrerQuery.isLoading) {
    log.debug('Loading Referrer...');
  }

  if (referrer && referrerQuery.isError) {
    //return <Alert severity="error">Failed to retrieve Referrer!</Alert>;
    log.error('Unable to retrieve referrer.');
  }
  const resend = async () => {
    try {
      await resendCode(user.email);
    } catch (e) {
      logError(e);
      toast.error(e.message);
    }
  };
  const confFormik = useFormik({
    initialValues: {
      confirmationCode: '',
    },
    validationSchema: yup.object({
      confirmationCode: yup
        .string()
        .required('You must provide a confirmation code'),
    }),
    onSubmit: async (values) => {
      try {
        segment.track('Verify Code Submitted', { email: user.email });
        // Verify Code
        await verifyCode(user.email, values.confirmationCode);
        await waitForSignIn();
        user.onboarding = OnboardingStep.VALUES;
        const body = {
          user: user,
          referrer: recommender?.account_id || null,
          preview: recommender ? 'beta' : null,
          display_name: user['firstname'] || '',
        };
        const updatedUser: User = await userMutation.mutateAsync(body);

        refreshUser(updatedUser);
        if (recommender) {
          await addUserMutation.mutateAsync(recommender);
          toast.success(
            recommender.display_name + ' is added to your network!'
          );
          // Capturing the referrer happens here for username+password, but in
          // useGetReferrerMutation for social signups
          segment.identify(updatedUser.user_id, {
            referrer_account_name: referrer,
          });
          segment.track('Referrer Added', {
            referrer_account_name: referrer,
            debug_loc: 'verify.tsx',
          });
        }

        nextStep(OnboardingStep.VALUES);
      } catch (e) {
        logError(e);
        toast.error(e.message);
      }
    },
  });
  const recommender = referrerQuery.data;

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.pastel.light,
        height: '125vh',
        minHeight: '125vh',
      }}
    >
      <form onSubmit={confFormik.handleSubmit}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Box
            sx={{
              width: { xs: '75%', md: '35%' },
              mt: { xs: '10px', sm: '80px' },
              mx: { xs: '30px', sm: '0px' },
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 40,
                fontFamily: 'Poppins',
                marginTop: '60px',
              }}
              textAlign="left"
            >
              Verify Email Address
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: '75%', md: '35%' },
              mt: { xs: '10px', sm: '80px' },
              mx: { xs: '30px', sm: '0px' },
            }}
          >
            <Typography color={theme.palette.greyCustom.dark} textAlign="left">
              A code was sent to{' '}
              <span style={{ fontWeight: 'bold' }}>{user.email}</span>. Please
              do not navigate away from this page!
            </Typography>
          </Box>
          <TextField
            sx={{
              width: { xs: '75%', md: '35%' },
              backgroundColor: theme.palette.white.main,
            }}
            label="Confirmation Code"
            variant="outlined"
            name={'confirmationCode'}
            value={confFormik.values.confirmationCode}
            onChange={confFormik.handleChange}
            error={
              confFormik.touched.confirmationCode &&
              Boolean(confFormik.errors.confirmationCode)
            }
            helperText={
              confFormik.touched.confirmationCode &&
              confFormik.errors.confirmationCode
            }
          />
          <LoadingButton
            size={'large'}
            variant={'contained'}
            type={'submit'}
            sx={{ mt: 4, width: { xs: '75%', md: '35%' } }}
            disabled={confFormik.isSubmitting}
          >
            Verify
          </LoadingButton>
        </Stack>
      </form>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginTop="10px"
      >
        <LoadingButton
          size={'large'}
          type={'submit'}
          onClick={resend}
          sx={{
            mb: 4,
            width: { xs: '75%', md: '50%' },
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&.MuiButton-text': {
              outline: 'none',
            },
          }}
        >
          Resend Code
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default VerifyPage;
