import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useAuth } from 'src/hooks/use-auth';
import { useGetOnboardingExperts } from 'src/hooks/recommended-experts';
import { useSegment } from '../../../contexts/segment-context';
import log from 'loglevel';
import { toast } from 'react-hot-toast';
import { BackProgressBar } from '../components/BackProgressBar';
import { FinalStepBar } from '../components/FinalStepBar';
import { useGetProfile } from 'src/hooks/user-hooks';
import { useRedirectLink } from 'src/hooks/redirect-hooks';
import { useNavigate } from 'react-router-dom';
import { useUpdateUserOnboardingMutation } from 'src/hooks/user-hooks';
import { OnboardingStep } from '../../../types/user';
import { ACCOUNT_TYPES_TO_SHOW, EXPERT_TYPES_MAP } from '../types';
import { KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material';
import { OnboardingExpert } from 'src/types/onboarding';
import OnboardingExpertBlock from '../components/OnboardingExpert';
import { useLocalStorage, useReferrer } from 'src/lib/hooksLib';
import { useGetRefferer } from 'src/hooks/referrer-hooks';
import { useGetPublicProfiles } from 'src/hooks/account-hooks';
import AccountAvatar from 'src/components/AccountAvatar';
import { Account } from 'src/types/account';
import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';
import { logError } from '../../../lib/errorLib';
import { isChrome, isDesktop, isMobileSafari } from 'react-device-detect';
import { getExtensionURL } from '../../home/components/AddExtensionButton';

const getOtherExperts = (
  myAccountTypes: Array<string>,
  myValues: Array<string>,
  onboardingExperts: { [key: string]: Array<OnboardingExpert> }
) => {
  const otherExperts = [];

  for (const [key, value] of Object.entries(onboardingExperts)) {
    if (!myAccountTypes.includes(key)) {
      for (let expert of value) {
        if (myValues.some((value) => expert.values.includes(value))) {
          otherExperts.push(expert);
        }
      }
    }
  }

  return otherExperts.sort();
};

export default function ChooseRecommenders() {
  const { user } = useAuth();
  const segment = useSegment();
  const profileQuery = useGetProfile();
  const userMutation = useUpdateUserOnboardingMutation();
  const onboardingExperts = useGetOnboardingExperts();
  const { getRedirect, clearRedirect } = useRedirectLink();
  const navigate = useNavigate();

  const [myValues, setMyValues] = useState<string[]>([]);
  const [myAccountTypes, setMyAccountTypes] = useState<string[]>([]);

  // isInSetupFlow is true if there is a redirect link in Local Storage. This
  // happens when the extension is installed but the user is not logged or
  // hasn't yet created an account. In those cases, we save a redirect link and
  // direct them to sign up or login. Once they do that, we send them to the
  // redirect link.
  const [isInSetupFlow, setIsInSetupFlow] = useState(false);
  const [isGoingBack, setIsGoingBack] = useState(false);
  const [isGoingNext, setIsGoingNext] = useState(false);
  const [accountTypesShown, setAccountTypesShown] = useState<string[]>([]);
  const [showOthersBlock, setShowOthersBlock] = useState(false);
  const [referrer] = useReferrer();
  const [redirectToExtensionDownload, setRedirectToExtensionDownload] =
    useState(false);
  const extensionURL = getExtensionURL(referrer);

  const accountsToRedirect = useFeatureValue(
    'onboarding-redirect-to-extension-download',
    { account_names: [] }
  );

  // Check if we should redirect to the shopper to the extension download
  useEffect(() => {
    if (
      accountsToRedirect.account_names.includes(referrer) &&
      (isMobileSafari || (isDesktop && isChrome))
    ) {
      setRedirectToExtensionDownload(true);
    }
    log.debug({ accountsToRedirect, referrer });
  }, [accountsToRedirect, referrer]);

  useEffect(() => {
    // If my account types filtered by types to show is 1 then show the others block
    if (Array.isArray(accountTypesShown) && accountTypesShown.length <= 1) {
      setShowOthersBlock(true);
    } else {
      setShowOthersBlock(false);
    }
  }, [accountTypesShown, setShowOthersBlock]);

  useEffect(() => {
    if (profileQuery.data && onboardingExperts.data) {
      setMyValues(profileQuery.data.active_account?.my_values || []);
      setMyAccountTypes(
        profileQuery.data.active_account?.my_account_types || []
      );
      setAccountTypesShown(
        (profileQuery.data.active_account?.my_account_types || []).filter(
          (type) => {
            return (
              ACCOUNT_TYPES_TO_SHOW.includes(type) &&
              (onboardingExperts.data?.[type] ?? []).length !== 0
            );
          }
        )
      );
    }
  }, [profileQuery.data, onboardingExperts.data]);

  useEffect(() => {
    if (getRedirect()) {
      setIsInSetupFlow(true);
    }
  }, [getRedirect]);

  const goToNextStep = async () => {
    try {
      setIsGoingNext(true);
      user.onboarding = OnboardingStep.COMPLETE;
      await userMutation.mutateAsync(user);

      if (isInSetupFlow) {
        clearRedirect();
        setIsGoingNext(false);
        navigate(getRedirect());
      } else {
        if (redirectToExtensionDownload) {
          segment.track('Add Extension Click', {
            category: 'add-extension-button',
            location: 'onboarding',
          });
          // On iOS, the new window will open the App store and this tab will
          // remain open. So we need to navigate to the dashboard.
          if (isMobileSafari) {
            window.location.href = extensionURL;
            setIsGoingNext(false);
            navigate('/');
          }
          if (isDesktop && isChrome) {
            window.location.href = extensionURL;
          }
        } else {
          setIsGoingNext(false);
          navigate('/');
        }
      }
    } catch (err) {
      setIsGoingNext(false);
      toast.error(err.message);
      logError(err, 'Error in onboarding, final step, final click');
    }
  };

  const goToPrevStep = async () => {
    setIsGoingBack(true);
    try {
      user.onboarding = OnboardingStep.ACCOUNT_TYPES;
      // Save the account_types so you store them when coming back here
      await userMutation.mutateAsync(user);

      navigate('/onboarding/expert_types');
      setIsGoingBack(false);
    } catch (err) {
      toast.error(err.message);
      setIsGoingBack(false);
      logError(err, 'Error in onboarding, final step, going to previous');
    }
  };

  const getNextButtonLabel = () => {
    if (isInSetupFlow) return 'Finish Setup';
    if (!redirectToExtensionDownload) return 'Start Shopping';
    if (redirectToExtensionDownload && isMobileSafari)
      return 'Download iOS Extension';
    if (redirectToExtensionDownload && isDesktop && isChrome)
      return 'Download Chrome Extension';
    return 'Start Shopping';
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
      }}
    >
      <BackProgressBar
        progress={100}
        showPrev={true}
        enabled={!isGoingBack}
        onClick={goToPrevStep}
      />
      <Stack
        direction="column"
        sx={{
          height: '100%',
          py: 8,
          maxWidth: 800,
          margin: '0 auto 32px auto',
        }}
      >
        <Box
          sx={{
            px: { xs: 3, md: 0 },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              mb: 2,
              textAlign: 'left',
            }}
          >
            Experts picked for you
          </Typography>
        </Box>
        <RecommenderBlock myValues={myValues} />
        {accountTypesShown.map((type) => {
          return (
            <ExpertTypeBlock
              key={type}
              type={type}
              experts={onboardingExperts.data?.[type] ?? []}
              my_values={myValues}
            />
          );
        })}
        {showOthersBlock && (
          <ExpertTypeBlock
            type={'other'}
            experts={getOtherExperts(
              myAccountTypes,
              myValues,
              onboardingExperts.data || {}
            )}
            my_values={myValues}
          />
        )}
      </Stack>
      <FinalStepBar
        label={getNextButtonLabel()}
        enabled={!isGoingNext}
        onClick={goToNextStep}
      />
    </Box>
  );
}

type ExpertsRecommendedByReferrer = {
  default: Array<{ account_name: string }>;
  message: string;
};

const RecommenderBlock = ({ myValues }) => {
  const gb = useGrowthBook();
  const [referrer] = useLocalStorage('referrer', null);
  const referrerQuery = useGetRefferer(referrer);
  const recommendedByFeatures =
    gb.getFeatureValue<ExpertsRecommendedByReferrer | null>(
      `recommended-experts-${referrer}`,
      null
    );

  const profilesQuery = useGetPublicProfiles(
    [
      ...(recommendedByFeatures?.default?.map((a) => a.account_name) || []),
      referrer,
    ],
    true
  );

  if (!referrerQuery.data) {
    return null;
  }

  if (
    !recommendedByFeatures?.default ||
    recommendedByFeatures?.default?.length === 0
  ) {
    return (
      <ExpertTypeBlock
        type={'referrer'}
        experts={[referrerQuery.data]}
        my_values={myValues}
      />
    );
  }

  return (
    <Box>
      <ExpertTypeBlock
        type={'referrer-recommended'}
        experts={
          profilesQuery.some((p) => p.isLoading === true)
            ? []
            : profilesQuery
                .map((p) => p.data)
                .filter((p) => !!p)
                .map((a) => ({
                  account_id: a.account_id,
                  account_name: a.account_name,
                  display_name: a.display_name,
                  avatar_url: a.avatar_url,
                  full_name: a.full_name,
                  short_desc: a.short_desc,
                  long_desc: a.long_desc,
                  avatar_url_tn: a.avatar_url_tn,
                  account_status: a.account_status,
                  account_type: a.account_type,
                  values: a.values,
                  descriptor: a.descriptor,
                }))
        }
        my_values={myValues}
        referrer={referrerQuery.data}
        message={recommendedByFeatures?.message}
      />
    </Box>
  );
};

const ExpertTypeBlock = ({
  type,
  experts,
  my_values,
  referrer,
  message,
}: {
  type: string;
  experts: Array<OnboardingExpert>;
  my_values: Array<string>;
  referrer?: Account;
  message?: string;
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [expanded, setExpanded] = useState(false);

  if (type === 'other' && experts.length === 0) {
    return null;
  }

  return (
    <Stack direction="column" sx={{ mb: 4 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          mb: 2,
          px: { xs: 3, md: 0 },
        }}
      >
        {type === 'referrer-recommended' ? (
          <ReferrerBlurb referrer={referrer} message={message}></ReferrerBlurb>
        ) : type === 'referrer' ? (
          <Box></Box>
        ) : type === 'other' ? (
          <Typography
            sx={{
              fontWeight: 600,
            }}
          >
            Other Experts that match your values but not your preferred expert
            type
          </Typography>
        ) : (
          <Typography
            sx={{
              color: 'greyCustom.main',
              fontWeight: 400,
            }}
            component="span"
          >
            Recommended because you picked{' '}
            <Chip
              sx={{
                fontWeight: 500,
                backgroundColor: 'white.main',
              }}
              variant="outlined"
              label={EXPERT_TYPES_MAP[type]?.title || 'Unknown'}
            />
          </Typography>
        )}
        {isMd && experts.length > 3 && (
          <Button
            onClick={() => setExpanded((e) => !e)}
            variant="text"
            sx={{ px: 0 }}
          >
            {expanded ? (
              <Typography
                sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}
              >
                Show less <KeyboardArrowUp />
              </Typography>
            ) : (
              <Typography
                sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}
              >
                Show all <KeyboardArrowRight />
              </Typography>
            )}
          </Button>
        )}
      </Box>
      <Stack
        sx={{
          overflowX: {
            xs: 'scroll',
            md: 'hidden',
          },
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flexShrink: 0,
          justifyContent: {
            xs: 'start',
            md: 'stretch',
          },
          alignItems: {
            xs: 'start',
            md: 'stretch',
          },
          flexWrap: {
            xs: 'nowrap',
            md: 'wrap',
          },
          gap: '10px',
          rowGap: '10px',
        }}
      >
        {experts
          .sort((a, b) => {
            // For the referrer-recommended type - push the referrer all the way to the end
            if (
              type === 'referrer-recommended' &&
              a.account_name === referrer?.account_name
            ) {
              return 1;
            }

            // Sort by experts which have the most values in common with you
            const aSharedValues = (a?.values ?? []).filter((value) =>
              my_values.includes(value)
            );
            const bSharedValues = (b?.values ?? []).filter((value) =>
              my_values.includes(value)
            );
            return bSharedValues.length - aSharedValues.length;
          })
          .slice(0, isMd && !expanded ? 3 : experts.length)
          .map((expert, index) => {
            return (
              <Box
                key={index}
                sx={{
                  ml: { xs: index === 0 ? 3 : 0, md: 0 },
                }}
              >
                <OnboardingExpertBlock
                  expert={expert}
                  my_values={my_values}
                  auto_select={type === 'other' ? false : true}
                  force_auto_select={type === 'referrer-recommended'}
                ></OnboardingExpertBlock>
              </Box>
            );
          })}
      </Stack>
    </Stack>
  );
};

const ReferrerBlurb = ({ referrer, message }) => {
  if (!referrer) {
    return null;
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      sx={{
        xs: {
          px: 3,
        },
      }}
    >
      <Box>
        <AccountAvatar
          avatarBorder={{ border: 0 }}
          avatarSize={48}
          account={referrer}
        ></AccountAvatar>
      </Box>
      <Box sx={{ ml: 1, flexGrow: 1, maxWidth: '480px' }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '0.9rem',
          }}
        >
          Experts that{' '}
          <Typography
            component={'span'}
            sx={{
              color: 'primary.main',
              fontWeight: 600,
              verticalAlign: 'center',
              fontSize: '0.9rem',
            }}
          >
            @{referrer.account_name}
          </Typography>{' '}
          thinks you'll love
        </Typography>
        <Box
          sx={{
            marginTop: '2px',
            backgroundColor: 'white.main',
            borderRadius: '0 8px 8px 8px',
            padding: '8px',
          }}
        >
          <Typography
            sx={{
              fontSize: '0.8rem',
              fontStyle: 'italic',
              color: 'greyCustom.main',
            }}
          >
            {message}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};
