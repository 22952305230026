import { useEffect, useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useAuth } from 'src/hooks/use-auth';
import SectionContainer from 'src/components/SectionContainer';
import HowItWorks from 'src/pages/home/components/HowItWorks';
import SingleCategorySelector from './shop/SingleCategorySelector';
import { Link, useSearchParams } from 'react-router-dom';
import { useVectorSearch } from '../../../hooks/search-hooks';
import { useUrlState } from '../../../hooks/useUrlState';
import { ProductsList } from '../../shared/ProductList';
import { LoadingSpinner } from '../../../components/loading-spinner';
import GlobalVectorSearchFilterBar from '../../global-vector-search/components/GlobalVectorSearchFilterBar';
import { VectorProductSearchItem } from '../../../types/search';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSegment } from '../../../contexts/segment-context';
import { SortByOptions } from '../../global-vector-search/components/utilities';
import { ProfileSearchBar } from './ProfileSearchBar';

/**
 *
 * @param {*} param0
 * @returns
 */
export default function ProductsTab({ account, isMd }) {
  const { user } = useAuth();
  const segment = useSegment();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  // Added by steve on Dec 9, 2023. It was causing an error after migrating to SST 2,
  // I have no idea why it wasn't causing an error earlier. TBD investigation
  const [sortBy, setSortBy] = useState(SortByOptions.MostRelevant);

  //const [query, setQuery] = useState('');
  const [category, setCategory] = useUrlState('', 'ccat');
  const [query, setQuery] = useUrlState('', 'cq');
  //const [value, setValue] = useUrlState('', 'cval');
  const [filteredResults, setFilteredResults] =
    useState<VectorProductSearchItem[]>(null);
  // Execute the query if there's an account and either a search query
  // or a category selected
  const runQuery = !!account && (!!query || !!category);

  const vectorQuery = useVectorSearch(query, runQuery, {
    account_names: account.account_name ? [account.account_name] : undefined,
    product_categories: category ? [category] : undefined,
    score: !query && category ? '0' : undefined,
    limit: !query && category ? '1000' : undefined,
  });
  //vectorQuery.data && console.log(vectorQuery.data);

  useEffect(() => {
    if (!vectorQuery.data || !vectorQuery.data.results) return;
    setFilteredResults(vectorQuery.data.results);
  }, [vectorQuery.data]);

  const handleCancel = () => {
    setCategory('');
    setQuery('');
  };

  // Analytics
  useEffect(() => {
    if (!vectorQuery.data || !vectorQuery.data.results) return;
    const r = vectorQuery.data.results;
    //console.log('queryResponse.data', queryResponse.data);
    segment.track('Search Results Displayed', {
      context: 'profile',
      category: category,
      n_total: r.length,
      search_term: vectorQuery.data.query,
      search_id: vectorQuery.data.search_id,
      results: r.slice(0, 50).map((x) => x.product.product_id),
      page: page || 1,
    });
  }, [vectorQuery.data, segment, page, category]);

  const isGuest = user.user_id ? false : true;

  if (!isGuest && !user.active_account) return <div>Loading...</div>;

  const results = vectorQuery.data?.results;
  return (
    <Box>
      <Box minHeight={600}>
        <SectionContainer sx={{ pb: 1 }}>
          <ProfileSearchBar
            queryState={[query, setQuery]}
            backgroundColor="rgba(0, 0, 0, 0.06)"
            sx={{ mb: 1 }}
          />
          {results?.length > 0 && (
            <GlobalVectorSearchFilterBar
              products={results}
              setFilteredProducts={setFilteredResults}
              showAccounts={false}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          )}
        </SectionContainer>
        <SectionContainer
          backgroundColor="pastel.light"
          sx={{ py: { xs: 3, sm: 3 } }}
        >
          {runQuery ? (
            <Box>
              {category && (
                <Stack direction="row" alignItems={'center'} sx={{ mb: 1 }}>
                  <Typography fontSize={33} fontWeight={500}>
                    {category}
                  </Typography>
                  <IconButton onClick={handleCancel}>
                    <CancelIcon />
                  </IconButton>
                </Stack>
              )}
              <Box>
                {results && (
                  <Typography fontSize={17}>
                    {filteredResults?.length} results
                  </Typography>
                )}
                <ResultsSection
                  isLoading={vectorQuery.isLoading}
                  results={filteredResults}
                  primaryAccountId={account.account_id}
                />
              </Box>
            </Box>
          ) : (
            <>
              <Box>
                <Typography fontSize={22} fontWeight={600} sx={{ mb: 2 }}>
                  Explore {account.display_name}'s collections
                </Typography>
                <SingleCategorySelector
                  account={account}
                  onChange={(cat) => setCategory(cat)}
                  maxTags={isMd ? 12 : 4}
                />
              </Box>
            </>
          )}
        </SectionContainer>
      </Box>
      {isGuest && (
        <Box>
          <SectionContainer backgroundColor="black.light" sx={{ pb: 3, pt: 2 }}>
            <HowItWorks color="black.contrastText" />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 5,
              }}
            >
              <SignUpButton account={account} />
            </Box>
          </SectionContainer>
        </Box>
      )}
    </Box>
  );
}

const ResultsSection = ({ results, isLoading, primaryAccountId = null }) => {
  return (
    <Box>
      {isLoading && <LoadingSpinner />}
      {results?.length > 0 && !isLoading && (
        <ProductsList
          products={results}
          numPerPage={12}
          primaryAccountId={primaryAccountId}
        />
      )}
    </Box>
  );
};

const SignUpButton = ({ account }) => {
  const cta = "Try it - it's free!";
  const segment = useSegment();
  const handleClick = () => {
    segment.track('CTA Clicked', {
      context: 'profile',
      extension: false,
      category: 'sign_up_CTA',
      cta: cta,
      account_id: account?.account_id,
      account_name: account?.account_name,
    });
  };
  return (
    <Button
      sx={{
        width: 300,
        fontSize: 18,
        fontWeight: 600,
        backgroundColor: '#FFFFFF',
        color: 'primary.main',
      }}
      variant="contained"
      component={Link}
      to="/signup"
      onClick={handleClick}
    >
      {cta}
    </Button>
  );
};
