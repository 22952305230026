import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import SignupPage from './sign-up/sign-up';
import VerifyPage from './verify/verify';
import { OnboardingStep } from '../../types/user';
import { useAuth } from '../../hooks/use-auth';
import { OnboardingContext } from 'src/contexts/onboarding-context';

const handlePage = (onboardingStep: OnboardingStep, payload?: any) => {
  switch (onboardingStep) {
    case null || undefined:
      return <SignupPage />;
    case OnboardingStep.SIGNUP:
      return <SignupPage />;
    case OnboardingStep.VERIFY:
      return <VerifyPage />;
    case OnboardingStep.VALUES:
      return <Navigate to="/onboarding/values" />;
    case OnboardingStep.ACCOUNT_TYPES:
      return <Navigate to="/onboarding/account-types" />;
    case OnboardingStep.EXPERTS:
      return <Navigate to="/onboarding/experts" />;
    case OnboardingStep.COMPLETE:
      return <Navigate to="/dashboard" />;
    default:
      throw new Error('Unknown step');
  }
};

const OnboardPage = () => {
  const { user } = useAuth();
  let { onboardingStep } = useContext(OnboardingContext);
  if (!onboardingStep) {
    onboardingStep = user.onboarding;
  }
  return <>{handlePage(onboardingStep)}</>;
};

export default OnboardPage;
