import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import logoUrl from '../../../assets/logos/CountOn_primary.png';
import smallLogoUrl from '../../../assets/logos/CountOn_small.svg';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { useAuth } from '../../../hooks/use-auth';
import { ProfileSelector } from './ProfileSelector';
import log from 'loglevel';
import { gtm } from 'src/lib/gtm';
import { useSegment } from 'src/contexts/segment-context';
import AddExtensionButton from '../../../pages/home/components/AddExtensionButton';

const NavButton = ({
  children,
  to,
  variant = 'text',
  color = 'primary',
  onClick = () => {},
}: {
  children: React.ReactNode;
  to: string;
  variant?: 'text' | 'contained' | 'outlined';
  color?: string;
  onClick?: () => void;
}) => {
  const segment = useSegment();
  const handleClick = () => {
    segment.track('CTA Clicked', {
      extension: false,
      category: 'nav_item',
      cta: to,
    });
    onClick();
  };
  return (
    <Button
      variant={variant}
      component={Link}
      to={to}
      size="medium"
      sx={{
        minWidth: 'max-content',
        fontWeight: 700,
        fontSize: '15px',
        color,
      }}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};

const Topbar = ({ onSidebarOpen, queryState }) => {
  const { isAuthenticated, isExtInstalled } = useAuth();
  const segment = useSegment();

  const handleClick = (loc) => {
    segment.track('CTA Clicked', {
      extension: false,
      category: 'nav_item',
      cta: loc,
    });
  };

  return (
    <Box>
      {/* Desktop Top Nav */}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 1,
          height: 74,
        }}
      >
        <Box display={'flex'} component={Link} to="/">
          <Box component={'img'} src={logoUrl} height={74} />
        </Box>

        <SearchBar queryState={queryState} />
        <NavButton to="faq" color="black">
          FAQs
        </NavButton>
        {isAuthenticated ? (
          <>
            <ProfileSelector />
            {!isExtInstalled && (
              <AddExtensionButton
                gtmParams={{ location: 'topbar' }}
                sx={{ px: 2, height: 42 }}
              />
            )}
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to="login"
              size="medium"
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => handleClick('Log in')}
            >
              Log in
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="signup"
              size="medium"
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => handleClick('Sign up')}
            >
              Sign up
            </Button>
          </>
        )}
      </Stack>

      {/* Mobile Top Nav */}
      <Box display={{ xs: 'block', md: 'none' }} paddingTop={2}>
        <Box display="flex" justifyContent="space-between">
          <Box component={Link} to="/">
            <Box component="img" src={smallLogoUrl} height={55} />
          </Box>

          <IconButton onClick={() => onSidebarOpen()} aria-label="Menu">
            <MenuIcon sx={{ color: 'black', fontSize: 40 }} />
          </IconButton>
        </Box>

        <SearchBar queryState={queryState} />
      </Box>
    </Box>
  );
};

const SearchBar = ({ queryState }) => {
  const [searchText, setSearchText] = useState('');
  const [query, setQuery] = queryState;
  const segment = useSegment();
  //const { user } = useAuth();
  const navigate = useNavigate();
  const [sp, setSp] = useSearchParams();
  const location = useLocation();
  const searchRef = useRef<HTMLInputElement>(null);

  // On page load, check if there is a query in the URL and set it in the search bar
  useEffect(() => {
    const q = sp.get('q');
    log.trace('topbar search', q);
    if (q) setQuery(q);
  }, [setQuery, sp]);

  useEffect(() => {
    setSearchText(query);
  }, [query]);

  useEffect(() => {
    const onScroll = () => {
      // If the search bar is focused, blur it when the user scrolls
      if (searchRef.current === document.activeElement) {
        searchRef.current.blur();
      }
    };

    window.addEventListener('scroll', onScroll, true);

    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, []);

  const handleSubmit = () => {
    log.trace(location.pathname);
    gtm.push({
      event: 'search',
      search_term: searchText,
      search_location: 'topbar',
    });
    segment.track('Search Submitted', {
      search_term: searchText,
      extension: false,
      context: 'website',
    });
    setQuery(searchText);
    // If somebody is on the experts tab, keep them there. Otherwise send
    // to global search.
    if (location.pathname === '/search/experts')
      navigate(`/search/experts?q=${encodeURIComponent(searchText)}`);
    else navigate(`/search?q=${encodeURIComponent(searchText)}`);
  };

  const clearSearch = () => {
    setSearchText('');
    setQuery('');
    setSp((priorSp) => {
      priorSp.delete('q');
      return priorSp;
    });
    log.info('TopBar cleared search');
  };

  const inputStyle = {
    fontSize: 16,
    autocomplete: 'off',
    autocorrect: 'off',
    autocapitalize: 'none',
    spellcheck: 'false',
    padding: 0,
  };

  return (
    <Stack
      direction="row"
      height={74}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      <TextField
        fullWidth={true}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            searchRef.current.blur()!; // Blur the input so the keyboard goes away
            handleSubmit();
          }
        }}
        variant="filled"
        placeholder="Search for anything"
        aria-label="search query"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        inputProps={{
          name: 'counton-search',
          autoFocus: true,
          style: inputStyle,
          enterKeyHint: 'search',
        }}
        InputProps={{
          hiddenLabel: true,
          disableUnderline: true,
          startAdornment: !searchText && (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: 'black', fontSize: 30 }} />
            </InputAdornment>
          ),
          endAdornment: searchText && (
            <InputAdornment position="end" onClick={clearSearch}>
              <CloseIcon
                sx={{ color: 'black', fontSize: { xs: 20, md: 30 } }}
              />
            </InputAdornment>
          ),
          sx: {
            borderRadius: 6,
            paddingX: searchText ? '16px' : '10px',
            paddingY: '10px',
          },
        }}
        inputRef={searchRef}
      />
    </Stack>
  );
};

export default Topbar;
