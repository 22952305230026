import React, { createContext, useReducer } from 'react';
import { OnboardingStep } from '../types/user';
import { gtm } from 'src/lib/gtm';
import { segment } from 'src/lib/segment';
import { useGetTrustedAccountsQuery } from 'src/hooks/trusted-accounts-hooks';
import { useGetRatingSystems } from 'src/hooks/rating-system-hooks';

type ContextProps = {
  onboardingStep: OnboardingStep;
  nextStep: (payload: OnboardingStep) => void;
};

export const OnboardingContext = createContext<ContextProps>({
  onboardingStep: null,
  nextStep: () => null,
});

interface ProviderProps {
  children: React.ReactNode;
}

type State = {
  onboardingStep: OnboardingStep;
};

type Action = { type: 'next'; payload: OnboardingStep };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'next':
      return {
        ...state,
        onboardingStep: action.payload,
      };
    default:
      return state;
  }
}

export function OnboardingProvider({ children }: ProviderProps) {
  const [{ onboardingStep }, dispatch] = useReducer(reducer, {
    onboardingStep: null,
  });

  const nextStep = (payload) => {
    gtm.push({
      event: 'page_view',
      page_location: 'profile',
      page_title: payload,
    });
    segment.page('Onboarding', 'Profile', { pageTitle: payload });
    dispatch({ type: 'next', payload });
  };

  // Here to pre-cache the value for later (not ideal, but it's silly to wait
  // until the loading screen finished before executing these queries. A loading
  // screen followed by a loading spinner is no bueno. -steve
  useGetTrustedAccountsQuery();
  useGetRatingSystems();

  return (
    <OnboardingContext.Provider value={{ onboardingStep, nextStep }}>
      <div className="mui-step-form">{children}</div>
    </OnboardingContext.Provider>
  );
}
