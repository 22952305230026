import { Account } from './account';
export interface User {
  user_id: string;
  user_name?: string;
  email: string;
  role: Role;
  onboarding: OnboardingStep;
  active_account?: Account;
  active_account_id?: string;
  trusted_account_display_names?: string[];
  accounts?: Account[];
  // Wildcard fields
  [key: string]: any;
}

export enum OnboardingStep {
  SIGNUP = 'signup',
  VERIFY = 'verify',
  VALUES = 'values',
  ACCOUNT_TYPES = 'account_types',
  EXPERTS = 'experts',
  USERNAME = 'username',
  COMPLETE = 'complete',
  PAPERFORM = 'paper-form',
}

export enum Role {
  VIEWER = 'viewer',
  ADMIN = 'admin',
}
