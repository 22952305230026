import { useRef, useEffect } from 'react';
import { Box, Chip, Stack, Typography, Paper } from '@mui/material';
import AccountAvatar from 'src/components/AccountAvatar';
import { OnboardingExpert } from 'src/types/onboarding';
import CountOnButton from 'src/pages/shared/CountOnButton';
import { useSegment } from 'src/contexts/segment-context';
import {
  useAddTrustedAccountMutation,
  useGetTrustedAccountsQuery,
} from 'src/hooks/trusted-accounts-hooks';

type OnboardingExpertProps = {
  expert: OnboardingExpert;
  my_values: string[];
  auto_select?: boolean;
  force_auto_select?: boolean;
};

export default function OnboardingExpertBlock({
  expert,
  my_values,
  auto_select = false,
  force_auto_select = false,
}: OnboardingExpertProps) {
  const expertValues = (expert?.values ?? []).filter(
    (value) => value.trim() !== ''
  );
  const sharedValues = expertValues.filter((value) =>
    my_values.includes(value)
  );
  const segment = useSegment();

  let LIMIT =
    sharedValues.length > 0
      ? sharedValues.slice(0, 2).reduce((acc, value) => {
          return acc + value.length;
        }, 0) > 20
        ? 1
        : 2
      : expertValues.reduce((acc, value) => {
          return acc + value.length;
        }, 0) > 20
      ? 1
      : 2;

  const didAutoSelect = useRef(false);
  const { mutate: addTrustedMutate } = useAddTrustedAccountMutation();

  const { data: trustedAccounts } = useGetTrustedAccountsQuery();

  useEffect(() => {
    // If we should auto select this account and we haven't already auto selected then mutate to auto select it
    const wasAutoSelected = didAutoSelect.current;

    if (
      segment &&
      expert &&
      trustedAccounts &&
      ((auto_select && sharedValues.length > 0) || force_auto_select)
    ) {
      const isTrusted = trustedAccounts.some(
        (account) => account.account_id === expert.account_id
      );

      didAutoSelect.current = true;

      if (!isTrusted && !wasAutoSelected) {
        addTrustedMutate(expert);
        segment.track('Expert Added', {
          account_id: expert.account_id,
          account_name: expert.account_name,
          account_type: expert.account_type,
          values_shared: sharedValues,
          values_all: expert.values,
          auto_added: true,
          path: '/onboarding/experts',
        });
      }
    }
  }, [
    addTrustedMutate,
    trustedAccounts,
    expert,
    auto_select,
    sharedValues.length,
    force_auto_select,
    sharedValues,
    segment,
  ]);

  return (
    <Paper
      elevation={1}
      sx={{
        width: '260px',
        height: '280px',
        boxShadow: 1,
        borderRadius: '12px',
        border: '1px solid greyCustom.light',
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          p: 2,
        }}
      >
        <Box
          sx={{
            mb: 2,
          }}
        >
          <AccountAvatar
            avatarBorder={{ border: 0 }}
            avatarSize={64}
            account={expert}
          ></AccountAvatar>
        </Box>
        <Typography
          sx={{
            maxWidth: '80%',
            textOverflow: 'ellipsis',
            fontWeight: 600,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            height: '24px',
          }}
        >
          {expert.display_name}
        </Typography>
        <Typography
          sx={{
            maxWidth: '90%',
            textOverflow: 'ellipsis',
            fontSize: '0.8rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            height: '36px',
          }}
        >
          {expert.descriptor}
        </Typography>
        {sharedValues.length > 0 ? (
          <Box sx={{ height: '64px', width: '100%', px: 0, textAlign: 'left' }}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'greyCustom.main',
              }}
            >
              Values you both share
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent:
                  sharedValues.length > LIMIT ? 'space-between' : 'flex-start',
                flexFlow: 'row nowrap',
              }}
            >
              {sharedValues.slice(0, LIMIT).map((value) => {
                return (
                  <GreenChip
                    key={value}
                    sx={{
                      mr: sharedValues.length > LIMIT ? 0 : 1,
                    }}
                    value={value}
                  />
                );
              })}
              {sharedValues.length > LIMIT && (
                <NumberChip value={`+${sharedValues.length - LIMIT}`} />
              )}
            </Box>
          </Box>
        ) : expertValues.length > 0 ? (
          <Box
            sx={{
              height: '64px',
              width: '100%',
              px: 0,
              textAlign: 'left',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'greyCustom.main',
              }}
            >
              Values
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent:
                  expertValues.length > LIMIT ? 'space-between' : 'flex-start',
                flexFlow: 'row nowrap',
              }}
            >
              {expertValues.slice(0, LIMIT).map((value) => {
                return (
                  <GrayChip
                    key={value}
                    sx={{
                      mr: expertValues.length > LIMIT ? 0 : 1,
                    }}
                    value={value}
                  />
                );
              })}
              {expertValues.length > LIMIT && (
                <NumberChip value={`+${expertValues.length - LIMIT}`} />
              )}
            </Box>
          </Box>
        ) : (
          <Box height="64px"></Box>
        )}
        <Box
          sx={{
            mt: 0,
            width: '100%',
          }}
        >
          <CountOnButton
            sx={{
              width: '100%',
              height: '42px',
            }}
            account={expert}
          ></CountOnButton>
        </Box>
      </Stack>
    </Paper>
  );
}

const GreenChip = ({ value, sx }) => {
  return (
    <Chip
      sx={{
        '& .MuiChip-outlined': {
          borderSize: '2px',
        },
        border: 2,
        backgroundColor: 'green.light',
        '& .MuiChip-label': {
          color: 'black.dark',
          fontWeight: 600,
        },
        ...sx,
      }}
      variant="outlined"
      color="success"
      label={value}
    />
  );
};

const GrayChip = ({ value, sx }) => {
  return (
    <Chip
      sx={{
        // '& .MuiChip-outlined': {
        //   borderSize: '2px',
        //   borderColor: 'greyCustom.light',
        //   border: '2px solid greyCustom.light',
        // },
        // border: 2,
        // backgroundColor: 'white.main',
        border: '2px solid #ccc',
        '& .MuiChip-label': {
          color: 'black.dark',
          fontWeight: 600,
        },
        ...sx,
      }}
      variant="outlined"
      label={value}
    />
  );
};

const NumberChip = ({ value }) => {
  return (
    <Chip
      sx={{
        '& .MuiChip-outlined': {
          borderSize: '2px',
        },
        border: 2,
        borderColor: 'greyCustom.light',
        backgroundColor: 'white.main',
        '& .MuiChip-label': {
          color: 'primary.main',
          fontWeight: 600,
        },
      }}
      label={value}
    />
  );
};
