import log from 'loglevel';
import { useAuth } from '../use-auth';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { logError } from '../../lib/errorLib';
import { API } from 'aws-amplify';
import { Account } from '../../types/account';
import { useGuestId } from '../guest-id-hook';

type CreateFeedbackBody = {
  guest_id?: string | null;
  analysis_id: string;
  run_id: string;
  score: number;
  account_name: string; // Will be linked to the account_id in the BE
  analysis_type: 'ingredient' | 'product_summary';
  original_text: string;
  correction?: string;
  comment?: string;
  feedback_id?: string;
};

export type Feedback = {
  feedback_id: string;
  user_id: string;
  guest_id: string;
  analysis_id: string;
  run_id: string;
  score: number;
  analysis_account_id: string;
  analysis_type: string;
  original_text: string;
  correction: string;
  comment: string;
  created_at: string;
  updated_at: string;
  analysis_account: Account;
};

type CreateFeedbackResponse = {
  status: string;
  feedback: Feedback;
};

function createFeedback(
  body: CreateFeedbackBody,
  isAuthenticated: boolean,
): Promise<CreateFeedbackResponse> {
  log.debug('Creating feedback', body);
  const apiPath = `${isAuthenticated ? '' : '/guest'}/ai/feedback`;
  return API.post('trustable', apiPath, { body });
}

export function useCreateFeedbackMutation(
  mutOptions?: UseMutationOptions<
    CreateFeedbackResponse,
    Error,
    CreateFeedbackBody
  >,
) {
  const { isAuthenticated } = useAuth();
  const { guest_id } = useGuestId();
  const queryClient = useQueryClient();

  const mutation = useMutation<
    CreateFeedbackResponse,
    Error,
    CreateFeedbackBody
  >(
    (body: CreateFeedbackBody) =>
      createFeedback({ ...body, guest_id }, isAuthenticated),
    {
      onSuccess: (data) => {
        log.debug('Feedback saved', data);
        queryClient.invalidateQueries([
          'feedback',
          'analysis',
          data?.feedback?.analysis_id,
        ]);
      },
      onError: (error) => {
        logError('Failed to save feedback', error);
      },
      ...mutOptions,
    },
  );
  return mutation;
}

type UpdateFeedbackBody = {
  guest_id?: string | null;
  score: number;
  correction?: string | null;
  comment?: string | null;
  feedback_id: string;
};

type UpdateFeedbackResponse = {
  status: string;
  feedback: Feedback;
};

function updateFeedback(
  body: UpdateFeedbackBody,
  isAuthenticated: boolean,
): Promise<UpdateFeedbackResponse> {
  log.debug('Updating feedback', body);
  const apiPath = `${isAuthenticated ? '' : '/guest'}/ai/feedback/${
    body.feedback_id
  }`;
  return API.put('trustable', apiPath, { body });
}

export function useUpdateFeedbackMutation(
  mutOptions?: UseMutationOptions<
    UpdateFeedbackResponse,
    Error,
    UpdateFeedbackBody
  >,
) {
  const { isAuthenticated } = useAuth();
  const { guest_id } = useGuestId();
  const queryClient = useQueryClient();

  const mutation = useMutation<
    UpdateFeedbackResponse,
    Error,
    UpdateFeedbackBody
  >(
    (body: UpdateFeedbackBody) =>
      updateFeedback({ ...body, guest_id }, isAuthenticated),
    {
      onSuccess: (data) => {
        log.debug('Feedback updated', data);
        queryClient.invalidateQueries([
          'feedback',
          'analysis',
          data?.feedback?.analysis_id,
        ]);
      },
      onError: (error) => {
        logError('Failed to update feedback', error);
      },
      ...mutOptions,
    },
  );
  return mutation;
}

type AnalysisFeedbackResponse = {
  status: string;
  feedback: Feedback[];
};

function getAnalysisFeedback(analysis_id: string, guest_id?: string | null) {
  log.debug('Getting analysis feedback', { analysis_id, guest_id });
  const apiPath = `${
    guest_id ? '/guest' : ''
  }/ai/feedback/analysis/${analysis_id}${
    guest_id ? '?guest_id=' + guest_id : ''
  }`;
  return API.get('trustable', apiPath, {});
}

export function useAnalysisFeedback(analysis_id: string) {
  const { isAuthenticated } = useAuth();
  // Get the guest_id if the user is not authenticated
  const { guest_id: gid } = useGuestId();
  const guest_id = isAuthenticated ? null : gid;

  const feedback = useQuery<AnalysisFeedbackResponse, Error>(
    ['feedback', 'analysis', analysis_id],
    () => getAnalysisFeedback(analysis_id, guest_id),
    {
      enabled: !!analysis_id,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => log.trace('useAnalysisFeedback data', data),
      onError: (error) => logError(error),
    },
  );
  return feedback;
}
