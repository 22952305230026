import {
  AvatarGroup,
  Box,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { useSearchProductAnalyses } from '../../../hooks/ai/search-product-analyses-hook';
import { SavedProductAnalysis } from '../../../types/saved_product_analyses';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import CountOnAvatar from '../../../components/avatar/CountOnAvatar';

type RecentProductAnalysesProps = {
  account_name?: string;
  sx?: SxProps;
};

export default function RecentProductAnalyses({
  account_name,
  sx,
}: RecentProductAnalysesProps) {
  const query = useSearchProductAnalyses([account_name]);

  if (query.isLoading) return null;

  if (query.isError) return null;

  const analyses = query.data?.analyses || [];

  if (analyses.length === 0) return null;

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        bgcolor: '#FFFBF7',
        p: { xs: 1, sm: 2 },
        width: 1,
        maxWidth: 1000,
        ...sx,
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 22, sm: 31 },
          lineHeight: { xs: 1, sm: 1 },
          fontWeight: 500,
          mb: 2,
        }}
      >
        Recent products analyzed with{' '}
        <Typography variant="inherit" component="span" color="primary">
          {account_name}
        </Typography>
        ’s expertise
      </Typography>
      {analyses.map((analysis) => (
        <RecentProductAnalysis key={analysis.analysis_id} analysis={analysis} />
      ))}
    </Stack>
  );
}

type RecentProductAnalysisProps = {
  analysis: SavedProductAnalysis;
};
const RecentProductAnalysis = ({ analysis }: RecentProductAnalysisProps) => {
  return (
    <Stack
      direction="row"
      alignItems={'center'}
      spacing={{ xs: 0, sm: 1 }}
      sx={{ maxWidth: 1000 }}
    >
      <IconButton
        component={Link}
        to={`/analyze-page/${analysis.analysis_id}`}
        target="_blank"
      >
        <OpenInNewIcon />
      </IconButton>
      <Stack
        direction="column"
        spacing={{ xs: 0.5, sm: -1 }}
        // Necessary for ellipses on url's to work
        sx={{ minWidth: 0 }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
          spacing={{ xs: 0, sm: 0.5 }}
          // Necessary for ellipses on url's to work
          sx={{ minWidth: 0 }}
        >
          <Box sx={{ maxWidth: { xs: '100%', sm: '80%' } }}>
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: { xs: 16, sm: 23 },
                fontWeight: 600,
              }}
            >
              {analysis.brand} | {analysis.title}
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: { xs: 12, sm: 16 },
                color: 'grey',
              }}
            >
              {analysis.url}
            </Typography>
          </Box>
          <AvatarGroup
            sx={{
              '& .MuiAvatar-root': {
                width: { xs: 28, sm: 35 },
                height: { xs: 28, sm: 35 },
                fontSize: 15,
              },
            }}
          >
            {analysis.account_names.map((account_name, i) => (
              <CountOnAvatar key={i} account_name={account_name} />
            ))}
          </AvatarGroup>
        </Stack>
      </Stack>
    </Stack>
  );
};
