export const EXPERT_TYPES_MAP = {
  creator: {
    title: 'Specialized Content Creator',
    description: 'e.g. Bloggers, Influencers, YouTubers',
  },
  certifier: {
    title: 'Certifications',
    description: 'e.g. Climate Neutral, Organic, Fair Trade',
  },
  media_publication: {
    title: 'Media Publications',
    description: 'e.g. NYT Wirecutter, The Strategist',
  },
  personal: { title: 'Friends / Family', description: null },
  watchdog_agency: {
    title: 'Consumer Watchdog Agencies',
    description: 'e.g. EWG, Consumer Reports',
  },
  retailer: {
    title: 'Specialty Retailers',
    description: 'e.g. Thrive Market, Earth Hero, Life Without Plastic',
  },
  medical_provider: {
    title: 'Medical Providers',
    description: 'e.g. Ob/Gyn, Family Doctor, Midwife',
  },
};

export const ACCOUNT_TYPES_TO_SHOW = [
  'certifier',
  'creator',
  'media_publication',
  'watchdog_agency',
  'retailer',
  'medical_provider',
];
