import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Input, InputAdornment } from '@mui/material';
import log from 'loglevel';
import { gtm } from 'src/lib/gtm';
import { useSegment } from 'src/contexts/segment-context';

/**
 * queryState is providing by the controlling parent. It looks like
 * "queryState=[query, setQuery]"" like from useState('')
 *
 * The setter in queryState is called when the user hits the enter key. The
 * 'searchText' state in this component handles the on-every-keystroke updates.
 *
 * Chrome and autocomplete is madness. See
 * https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
 *
 * @param {*} param0
 * @returns
 */
export const ProfileSearchBar = ({
  queryState,
  autoFocus = false,
  backgroundColor = '#FFFFFF',
  placeholder = 'Product, brand, or value',
  sx = {},
}) => {
  const [query, setQuery] = queryState;
  const [searchText, setSearchText] = useState(query);
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const segment = useSegment();

  // If the query is changed elsewhere, this reflects that in this widgets
  useEffect(() => {
    setSearchText(query);
  }, [query]);

  const handleSubmit = () => {
    setQuery(searchText);
    log.trace('ProfileSearchBar query', searchText);
    gtm.push({
      event: 'search',
      search_term: searchText,
      search_location: 'searchbar',
    });
    segment.track('Search Submitted', {
      search_term: searchText,
      context: 'profile',
      extension: false,
    });
    if (searchBoxRef.current) {
      const y =
        searchBoxRef.current.getBoundingClientRect().top + window.scrollY - 135;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const clearSearch = () => {
    setSearchText('');
    setQuery('');
    log.trace('ProfileSearchBar cleared');
  };

  return (
    <Input
      fullWidth
      ref={searchBoxRef}
      onKeyDown={(ev) => {
        if (ev.key === 'Enter') {
          handleSubmit();
        }
      }}
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: '8px',
        py: 1,
        px: 1,
        border: backgroundColor === '#FFFFFF' ? 2 : 0,
        borderColor: 'greyCustom.light',
        fontSize: 18,
        fontWeight: 600,
        name: 'counton-search-1',
        autocomplete: 'do-not-autofill-counton',
        autocorrect: 'off',
        autocapitalize: 'none',
        spellCheck: 'false',
        ...sx,
      }}
      autoFocus={autoFocus}
      placeholder={placeholder}
      aria-label="search query"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      disableUnderline={true}
      startAdornment={
        <InputAdornment position="end">
          <SearchIcon
            sx={{ color: 'black', fontSize: 30, mr: 1 }}
            onClick={handleSubmit}
          />
        </InputAdornment>
      }
      endAdornment={
        searchText && (
          <InputAdornment position="end" onClick={clearSearch}>
            <CloseIcon sx={{ color: 'black', fontSize: 30 }} />
          </InputAdornment>
        )
      }
    />
  );
};
