//import { parseExtensionUrl } from 'src/shared/extensionTargets';
import { Link, useSearchParams } from 'react-router-dom';
import log from 'loglevel';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import logoUrl from 'src/assets/logos/CountOn_primary.png';
import CloseIcon from '@mui/icons-material/Close';
import { gtm } from 'src/lib/gtm';
import { useSegment } from 'src/contexts/segment-context';
import { useEffect } from 'react';
import { useGetPageData } from './extractor-hooks';
import { parseExtensionUrl } from '../../../shared/extensionTargets';
import { useAuth } from '../../../hooks/use-auth';
import ReviewsForAProduct from './ReviewsForAProduct';
import { SafariSetupGoogleSearch } from 'src/pages/onboarding/setup-extension/SafariSetupGoogleSearch';
import { isMobileSafari } from 'react-device-detect';
/**
 * This is called by the "ext/recommend" route, which is a misnomer. The
 * original browser extension only used the popup to make recommendations, so
 * 'ext/recommend' made sense. The popup is now being used for a variety of
 * purposes, like:
 *
 * 1. Summarizing the number of ratings on a search (duplicative of the
 *    carousel, but we need to show something if a shopper clicks it on a search
 *    page)
 * 2. Summarizing any ratings for a particular product detail page
 * 3. Telling a shopper there is nothing to see here...
 *
 * For the MVP (Apr 2023), the popup doesn't actually do much and is unlikely to
 * get used much, so we're not too concerned with the implemention.
 *
 * For the original code, see website/src/containers/extension/RecommendItem.js
 *
 * Test search results page
 * https://localhost:3000/ext/recommend?host=https%3A%2F%2Fwww.amazon.com%2Fs%3Fk%3Ddetergent%26crid%3D3O5PR5JJ0YCMA%26sprefix%3Ddeterge%252Caps%252C188%26ref%3Dnb_sb_noss_2
 * Test product detail page
 * http://localhost:3000/ext/recommend?host=https%3A%2F%2Fwww.amazon.com%2Fdp%2FB08GF7YGCD
 * Test unrecognized page
 * http://localhost:3000/ext/recommend?host=https%3A%2F%2Fwww.makes3organics.com%2Fcollections%2Findividual-bars%2Fproducts%2Fsuper-shea-soap-bar
 *
 * The hardcoded width and height should eventually be replaced with a message
 * passed from the iframe to the extension popup requesting a size change. This is how
 * the extension carousel and content script work.
 *
 */
export default function Popup() {
  const segment = useSegment();
  const { user } = useAuth();

  // How often is the popup actually used?
  useEffect(() => {
    gtm.push({
      event: 'ext_popup_loaded',
      category: 'ext',
    });
    segment.track('Extension Popup Loaded', { context: 'ext' });

    // Send the size of the iframe content to the parent window
    // NOTE: Chrome extension popups cannot be larger than 800x600
    // https://developer.chrome.com/docs/extensions/reference/browserAction#popup
    const sendHeightToParent = () => {
      const height = document.body.scrollHeight;
      const width = document.body.scrollWidth;
      window.parent.postMessage({ type: 'resize', height, width }, '*');
    };
    sendHeightToParent();
  }, [segment]);

  // if (user?.role === 'admin') return <AdminPopup />;

  return <DefaultPopup />;
}

/**
 * A fixed height and width container that will fit within the extension popup.
 * Provides a basic CountOn logo and popup close functionality
 * @param param0
 * @returns
 */
const PopupContainer = ({ width = 360, height = 180, children }) => {
  const { user } = useAuth();
  return (
    <Stack direction="column" sx={{ width, height }}>
      <Stack direction="row" alignItems="center" sx={{ p: 1 }}>
        <Box component={'img'} src={logoUrl} height={48} />
        {user?.role === 'admin' && (
          <Typography variant="h6" fontWeight={600} sx={{ ml: 1 }}>
            ADMIN - hello {user?.user_name}
          </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={closePopup}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Stack>
      <Divider />
      <Box sx={{ flexGrow: 1, backgroundColor: 'pastel.light' }}>
        {children}
      </Box>
    </Stack>
  );
};

function DefaultPopup() {
  if (isMobileSafari) {
    return <SafariSetupGoogleSearch />;
  }
  return (
    <PopupContainer>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 1,
        }}
      >
        <Typography fontSize={20} fontWeight={600}>
          Visit your{' '}
          <Link
            to="/dashboard?source=ext_popup"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dashboard
          </Link>
        </Typography>
      </Box>
    </PopupContainer>
  );
}

function AdminPopup() {
  const [sp] = useSearchParams();
  const host = sp.get('host');
  const { site } = parseExtensionUrl(host);
  log.debug(`Host is ${host}`);

  const pageData = useGetPageData(site);
  //console.log('pageData', pageData);

  return (
    <PopupContainer width={727} height={600}>
      <Box sx={{ p: 2 }}>
        <ReviewsForAProduct
          brand={pageData['brand']}
          title={pageData['title']}
          onlyMatches={false}
        />
      </Box>
    </PopupContainer>
  );
}

function closePopup() {
  log.debug('Sending signal to CountOn popup to close');
  window.parent.postMessage({ type: 'close' }, '*');
}
