import { FC } from 'react';
import { AuthConsumer, AuthProvider } from '../contexts/amplify-context';
import { OnboardingProvider } from '../contexts/onboarding-context';
import { SignUpModalProvider } from 'src/contexts/signup-modal-context';
import { SegmentProvider } from '../contexts/segment-context';
import Page from '../components/Page';
import 'aos/dist/aos.css';
import { Toaster } from 'react-hot-toast';
import config from 'src/configs/aws-config';
import { RouteHandler } from './RouteHandler';
import { GrowthBookContainer } from 'src/lib/growthbook';

const App: FC = () => {
  return (
    <Page>
      <Toaster position="top-center" />
      <AuthProvider>
        <SegmentProvider>
          <GrowthBookContainer config={config.GROWTHBOOK_CONFIG}>
            <SignUpModalProvider>
              <OnboardingProvider>
                <AuthConsumer>{(auth) => <RouteHandler />}</AuthConsumer>
              </OnboardingProvider>
            </SignUpModalProvider>
          </GrowthBookContainer>
        </SegmentProvider>
      </AuthProvider>
    </Page>
  );
};

export default App;
