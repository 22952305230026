import { useEffect, useCallback, useState } from 'react';

import { IconButton, SxProps, Theme } from '@mui/material';
import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
  Bookmark,
  BookmarkBorder,
} from '@mui/icons-material';
import log from 'loglevel';

import { useAuth } from 'src/hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import { gtm } from 'src/lib/gtm';
import { useSegment } from 'src/contexts/segment-context';

import { useLocalStorage } from 'src/lib/hooksLib';
import {
  useFavoriteProductMutation,
  useGetIsProductFavorited,
  useUnfavoriteProductMutation,
} from 'src/hooks/favorite-product-hooks';

interface FavoriteButtonProps {
  title: string;
  product_id: string;
  analyticsData?: any;
  iconSx?: SxProps<Theme>;
  icon?: 'heart' | 'bookmark';
}

export default function FavoriteButton({
  title,
  product_id,
  analyticsData = {},
  iconSx = [],
  icon = 'heart',
}: FavoriteButtonProps) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [, setSaveProduct] = useLocalStorage('save-product', null);
  const [isFavorited, setIsFavorited] = useState(false);

  const isProductFavorited = useGetIsProductFavorited(product_id);
  const createFavorite = useFavoriteProductMutation(product_id);
  const deleteFavorite = useUnfavoriteProductMutation(product_id);
  const segment = useSegment();

  useEffect(() => {
    if (isProductFavorited) {
      setIsFavorited(isProductFavorited);
    }
  }, [isProductFavorited]);

  const handleFavoriteClick = useCallback(async () => {
    gtm.push({
      event: 'custom_click',
      category: 'favorite',
      title: title,
      id: product_id,
    });

    // If a product is already recommended, don't try to change anything

    // If favoriteItem exists, un-favorite it. If not, then make it a favorite
    if (isFavorited) {
      await deleteFavorite.mutate(product_id);
      setIsFavorited(false);
      segment.track('Unfavorite Clicked', analyticsData);
    } else {
      await createFavorite.mutate(product_id);
      segment.track('Favorite Clicked', analyticsData);
      setIsFavorited(true);
    }
  }, [
    title,
    product_id,
    isFavorited,
    createFavorite,
    deleteFavorite,
    segment,
    analyticsData,
  ]);

  // If this is a guest, return an unfilled heart that directs to signup
  // when clicked
  if (!isAuthenticated) {
    return (
      <FavoriteIconButton
        onClick={async () => {
          await setSaveProduct(product_id);
          segment.track('Favorite Clicked', analyticsData);
          navigate('/signup');
        }}
        selected={false}
        sx={[...(Array.isArray(iconSx) ? iconSx : [iconSx])]}
        icon={icon}
      />
    );
  }

  // If there aren't any details yet, just return an unfilled heart
  if (!product_id)
    return (
      <FavoriteIconButton
        onClick={() =>
          log.error('Favorite button cannot work without a product_id')
        }
        sx={[...(Array.isArray(iconSx) ? iconSx : [iconSx])]}
        icon={icon}
      />
    );

  return (
    <FavoriteIconButton
      disabled={createFavorite.isLoading || deleteFavorite.isLoading}
      onClick={() => handleFavoriteClick()}
      selected={isFavorited}
      sx={iconSx}
      icon={icon}
    />
  );
}

interface FavoriteIconButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  selected?: boolean;
  sx?: SxProps<Theme>;
  icon: 'heart' | 'bookmark';
}

export const FavoriteIconButton = ({
  disabled = false,
  onClick = () => {},
  selected = false,
  sx = [],
  icon,
}: FavoriteIconButtonProps) => {
  const baseSx = { fontSize: 'inherit', alignSelf: 'flex-start' };
  const isHeartIcon = icon === 'heart';

  return (
    <IconButton
      aria-label="favorite"
      size="large"
      sx={{
        p: 0.5,
        m: 0,
        alignSelf: baseSx.alignSelf,
        '&.MuiIconButton-root': {
          outline: 'none',
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {selected ? (
        isHeartIcon ? (
          <FavoriteIcon sx={sx} color="primary" />
        ) : (
          <Bookmark sx={sx} color="primary" />
        )
      ) : isHeartIcon ? (
        <FavoriteBorderIcon sx={sx} color="primary" />
      ) : (
        <BookmarkBorder sx={sx} color="primary" />
      )}
    </IconButton>
  );
};
