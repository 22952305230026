import { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { OnboardingStep } from '../../../types/user';
import { useAuth } from 'src/hooks/use-auth';
import { useUpdateUserOnboardingMutation } from 'src/hooks/user-hooks';
import { toast } from 'react-hot-toast';
import { ProductValueSelector } from 'src/components/ProductValueSelector';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { NextStepBar } from '../components/NextStepBar';
import { useUpdateAccountMutation } from 'src/hooks/account-hooks';
import { useNavigate } from 'react-router-dom';
import { BackProgressBar } from '../components/BackProgressBar';
import { useGetProfile } from 'src/hooks/user-hooks';
import { useSegment } from 'src/contexts/segment-context';
import { useReferrer } from 'src/lib/hooksLib';

const HEALTH_VALUES = ['Non-toxic', 'Organic', 'Fragrance-free', 'PFAS-free'];

const VALUE_MAP = {
  '💖 Health': HEALTH_VALUES,
  '🌎 Environment': [
    'Low carbon',
    'Circularity',
    'Plastic free',
    'Recyclable',
    'Reducing waste',
    'Sustainable sourcing',
  ],
  '👩‍🌾 Human wellbeing': [
    'Responsibly made',
    'Fair trade',
    'Minority owned',
    'Small business',
    'Woman owned',
    'LGBTQ+ owned',
  ],
  '🐄 Animal ethics': ['Cruelty-free', 'Vegan'],
};

const ValuesPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const profileQuery = useGetProfile();
  const userMutation = useUpdateUserOnboardingMutation();
  const accoutUpdateMutation = useUpdateAccountMutation();
  const gb = useGrowthBook();
  const valueMap = gb.getFeatureValue('onboarding-value-map', VALUE_MAP);
  const segment = useSegment();
  const [referrerAccountName] = useReferrer();

  const [selectedValues, setSelectedValues] = useState(
    user.active_account?.my_values ?? []
  );
  const [initialValues, setInitialValues] = useState(
    user.active_account?.my_values ?? []
  );

  useEffect(() => {
    if (profileQuery.data) {
      const myValues = profileQuery.data.active_account?.my_values ?? [];
      const valuesFromReferrer =
        referrerAccountName === 'gimmethegoodstuff' ? HEALTH_VALUES : [];
      const allValues = [...myValues, ...valuesFromReferrer];
      const uniqueValuesSet = new Set(allValues);
      const uniqueValuesArray = Array.from(uniqueValuesSet);

      setSelectedValues(uniqueValuesArray);
      setInitialValues(myValues);
    }
  }, [profileQuery.data, referrerAccountName]);

  const saveTag = (tag) => {
    setSelectedValues((tags) => {
      if (tags.includes(tag)) {
        return tags;
      }

      return [...tags, tag];
    });
  };

  const removeTag = (tag) => {
    setSelectedValues((tags) => {
      return tags.filter((t) => t !== tag);
    });
  };

  const reportAnalytics = () => {
    segment.track('Values Selected', {
      context: 'onboarding',
      values: selectedValues,
      path: '/onboarding/values',
    });

    const addedValues = selectedValues.filter(
      (v) => !initialValues.includes(v)
    );

    if (addedValues.length > 0) {
      segment.track('Values Added', {
        context: 'onboarding',
        values: addedValues,
        path: '/onboarding/values',
      });
    }

    const removedValues = initialValues.filter(
      (v) => !selectedValues.includes(v)
    );

    if (removedValues.length > 0) {
      segment.track('Values Removed', {
        context: 'onboarding',
        values: removedValues,
        path: '/onboarding/values',
      });
    }

    segment.identify(user.user_id, {
      my_values: selectedValues,
    });
  };

  const goToNextStep = async () => {
    setIsLoading(true);
    try {
      user.onboarding = OnboardingStep.ACCOUNT_TYPES;
      await userMutation.mutateAsync(user);
      await accoutUpdateMutation.mutateAsync({
        my_values: selectedValues,
      });

      reportAnalytics();

      navigate('/onboarding/expert_types');
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <BackProgressBar progress={33} showPrev={false} />

      <Stack
        direction="column"
        sx={{
          maxWidth: 800,
          margin: '0 auto',
          py: 8,
          px: { xs: 3, md: 0 },
          height: '100%',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            mb: 2,
            xs: { textAlign: 'left' },
            md: { textAlign: 'center' },
          }}
        >
          Tell us what values matter most to you
        </Typography>
        <Typography
          sx={{
            xs: { textAlign: 'left' },
            md: { textAlign: 'center' },
          }}
        >
          We'll use this to find experts and products that match your values.
          You can always update this later.
        </Typography>

        {Object.keys(valueMap).map((key) => {
          return (
            <Box key={key} sx={{ mt: 2, maxWidth: '100%' }}>
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                {key}
              </Typography>
              <Stack
                direction="row"
                flexWrap="wrap"
                spacing={0}
                sx={{ mt: 1, alignContent: 'flex-start' }}
              >
                {VALUE_MAP[key].map((value) => {
                  return (
                    <ProductValueSelector
                      sx={{ mb: 1, mr: 1 }}
                      key={value}
                      tag={value}
                      selected={selectedValues.includes(value)}
                      onClick={() => {
                        selectedValues.includes(value)
                          ? removeTag(value)
                          : saveTag(value);
                      }}
                    />
                  );
                })}
              </Stack>
            </Box>
          );
        })}
      </Stack>
      <NextStepBar
        enabled={selectedValues.length > 0 && !isLoading}
        onClick={goToNextStep}
      />
    </Box>
  );
};
export default ValuesPage;
