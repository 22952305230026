import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useAuth } from 'src/hooks/use-auth';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import log from 'loglevel';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useAddTrustedAccountMutation,
  useDropTrustedAccountMutation,
  useGetTrustedAccountsQuery,
} from 'src/hooks/trusted-accounts-hooks';
import { gtm } from 'src/lib/gtm';
import { useSegment } from 'src/contexts/segment-context';
import { toast } from 'react-hot-toast';

export default function CountOnButton({
  account,
  variant = 'button',
  sx = {},
  setError = () => {},
  noClick = false,
  size = 'large',
  analyticsData = {},
}) {
  const { isAuthenticated, user } = useAuth();
  const [accountIsTrusted, setAccountIsTrusted] = useState(null);
  const location = useLocation();
  const isGuest = !isAuthenticated;
  const navigate = useNavigate();
  const segment = useSegment();
  const isSizeSmall = size === 'small';

  const sharedValues = account?.values?.filter((v) => {
    return user ? user.active_account?.my_values?.includes(v) : false;
  });

  const {
    isLoading,
    isError,
    data: trustedAccounts,
    error,
  } = useGetTrustedAccountsQuery();

  useEffect(() => {
    if (!trustedAccounts || !account) return;
    const u = trustedAccounts.find(
      (x) => x.account_name.toLowerCase() === account.account_name.toLowerCase()
    );
    setAccountIsTrusted(u !== undefined);
  }, [trustedAccounts, account]);

  const dropAccountMutation = useDropTrustedAccountMutation();
  const addAccountMutation = useAddTrustedAccountMutation();

  const handleClick = (e) => {
    // Don't bubble up the click
    e.stopPropagation();

    if (isGuest) {
      segment.track('CTA Clicked', {
        account_id: account.account_id,
        account_name: account.account_name,
        extension: false,
        cta: 'CountOn!',
        category: 'expert',
        ...analyticsData,
      });
      navigate('/signup?from=add-trusted-account');
      return;
    }

    if (account.account_name === user.active_account.account_name) {
      toast("You can't stop Counting On yourself silly!", {
        icon: '🤪',
      });
      return;
    }

    if (accountIsTrusted) {
      // Includes, so drop
      dropAccountMutation.mutate(account, {
        onSuccess: () => {
          log.info(`You no longer CountOn ${account.account_name}`);
          gtm.push({
            event: 'un_counton_account',
            account_id: account.account_id,
            account_name: account.account_name,
          });
          segment.track('Expert Removed', {
            account_id: account.account_id,
            account_name: account.account_name,
            values_all: account.values,
            values_shared: sharedValues,
            path: location.pathname,
            ...analyticsData,
          });
        },
      });
    } else {
      // Doesn't include, so add
      addAccountMutation.mutate(account, {
        onSuccess: () => {
          log.info(`You now CountOn ${account.account_name}!`);
          gtm.push({
            event: 'counton_account',
            account_id: account.account_id,
            account_name: account.account_name,
          });
          segment.track('Expert Added', {
            account_id: account.account_id,
            account_name: account.account_name,
            values_all: account.values,
            values_shared: sharedValues,
            path: location.pathname,
            auto_added: false,
            ...analyticsData,
          });
        },
      });
    }
  };

  if (isError) {
    log.error(error);
    setError(error);
    return;
  }

  const isDisabled = () => {
    if (isGuest) return false;
    return isLoading || isError || !account;
  };

  // For guests, isLoading stays true all the time
  const getCta = () => {
    if (isGuest) return 'CountOn!';
    if (isLoading) return '...';
    return accountIsTrusted ? `Counting On` : `CountOn!`;
  };

  if (variant === 'button') {
    return (
      <ButtonVariant
        trusted={accountIsTrusted}
        onClick={noClick ? undefined : handleClick}
        disabled={isDisabled()}
        isSizeSmall={isSizeSmall}
        sx={sx}
      >
        {account ? (
          getCta()
        ) : (
          <span style={{ display: 'inline-block', width: 100 }} />
        )}
      </ButtonVariant>
    );
  } else {
    return (
      <IconVariant
        trusted={accountIsTrusted}
        onClick={noClick ? undefined : handleClick}
        disabled={isDisabled()}
        sx={sx}
      />
    );
  }
}

const ButtonVariant = ({
  children,
  trusted,
  onClick,
  disabled,
  sx,
  isSizeSmall,
}) => {
  const baselineSx = {
    borderRadius: '8px',
    '&.MuiButton-contained': {
      outline: 'none',
    },
    '&.MuiButton-outlined': {
      outline: 2,
    },
    fontWeight: '600',
    ...sx,
  };

  const mediumSx = {
    height: '50px',
    border: trusted ? 2 : 0,
    fontSize: '18px',
    ...baselineSx,
  };

  const smallSx = {
    height: '32px',
    border: trusted ? 1 : 0,
    fontSize: '16px',
    ...baselineSx,
  };

  return (
    <Button
      variant={trusted ? 'outlined' : 'contained'}
      color={trusted ? 'secondary' : 'primary'}
      sx={isSizeSmall ? smallSx : mediumSx}
      onClick={onClick}
      disabled={disabled}
      size="small"
    >
      {trusted ? (
        <CheckIcon sx={{ mr: 1.5 }} />
      ) : (
        <PersonAddAlt1Icon sx={{ mr: 1.5 }} />
      )}
      {children}
    </Button>
  );
};

const IconVariant = ({ trusted, onClick, disabled, sx }) => {
  const col = trusted ? 'secondary.main' : 'primary.main';

  return (
    <Box
      sx={{
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: col,
        ...sx,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {trusted ? (
        <CheckIcon sx={{ fontSize: 23, color: '#FFFFFF' }} />
      ) : (
        <AddIcon sx={{ fontSize: 23, color: '#FFFFFF' }} />
      )}
    </Box>
  );
};
