import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks/use-auth';

export const ExtensionInstalledCallback = ({ component }) => {
  const auth = useAuth();
  const location = useLocation();
  let redirectURL = auth.redirectURL;
  if (!redirectURL) {
    // This is for the case when you login via user/pwd
    // When you login via social signup, your location.state clears b/c
    // of the redirect to cognito
    redirectURL = location.state?.from?.state?.from || '';
  }
  return redirectURL === '/extension-installed' ? (
    <Navigate to={'/extension-installed'} replace />
  ) : (
    <>{component}</>
  );
};
