import { Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

type ProductValueProps = {
  tag: string;
  selected: boolean;
  onClick: () => void;
  sx?: any;
};

export const ProductValueSelector = ({
  tag,
  onClick,
  selected,
  sx = {},
}: ProductValueProps) => {
  return (
    <Chip
      sx={{
        fontWeight: 600,
        color: selected ? 'white !important' : 'primary.main !important',
        backgroundColor: selected
          ? 'primary.main !important'
          : 'white !important',
        ...sx,
      }}
      color="primary"
      variant={selected ? 'filled' : 'outlined'}
      label={tag}
      onClick={onClick}
      icon={selected ? <CloseIcon /> : <AddIcon />}
    />
  );
};
