import { useEffect } from 'react';
import { useAuth } from 'src/hooks/use-auth';
import { useLocation, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import {
  Alert,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import googlePNG from 'src/assets/social-icons/google.png';
import facebookSVG from 'src/assets/social-icons/facebook.svg';
import { logError } from 'src/lib/errorLib';
import { useSegment } from 'src/contexts/segment-context';
import { isWebView } from 'src/lib/contextLib';
import { useLocalStorage } from '../lib/hooksLib';

export default function Login() {
  const { login, signInWithGoogle, signInWithFb } = useAuth();
  let location = useLocation();
  const segment = useSegment();
  const from = location.state?.from || '/';
  const width = { xs: '75%', md: '40%' };

  useEffect(() => {
    document.title = 'CountOn Log In';
  }, []);

  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .email()
      .required('You must provide a valid email address'),
    password: yup.string().required('Please enter a password'),
  });

  // After logging in, we should always try to share auth state with the browser
  // extension.
  const [, setSyncAuth] = useLocalStorage('should-sync-auth', null);
  useEffect(() => {
    setSyncAuth(true);
  }, [setSyncAuth]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        segment.track('User Clicked Login', { method: 'email' });
        await login(values.email, values.password);
      } catch (e) {
        logError(e);
        toast.error(e.message);
      }
    },
  });

  const loginWithGoogle = async () => {
    try {
      segment.track('User Clicked Login', { method: 'google' });
      await signInWithGoogle(false, from);
    } catch (e) {
      logError(e);
      <Alert severity="error">Something went wrong</Alert>;
    }
  };
  const loginWithFb = async () => {
    try {
      segment.track('User Clicked Login', { method: 'facebook' });
      await signInWithFb(false, from);
    } catch (e) {
      logError(e);
      <Alert severity="error">Something went wrong</Alert>;
    }
  };
  const googleIcon = (
    <img alt="google" src={googlePNG} height={25} width={27} />
  );
  const fbIcon = <img alt="fb" src={facebookSVG} height={25} width={27} />;

  return (
    <Box
      sx={{
        backgroundColor: 'pastel.light',
        height: '125vh',
        minHeight: '125vh',
        pt: { xs: 1, sm: 4 },
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2.5}
        sx={{ mb: 1, pt: 2, width: width, mx: 'auto' }}
      >
        <Typography
          fontWeight="600"
          fontSize="40px"
          fontFamily="Poppins"
          textAlign="center"
          lineHeight={1.2}
        >
          Welcome Back to CountOn!
        </Typography>
        {!isWebView && (
          <Button
            variant="outlined"
            disableFocusRipple
            fullWidth
            sx={{
              borderColor: 'greyCustom.light',
              boxShadow: '1',
              backgroundColor: 'white.main',
              '&:hover': {
                backgroundColor: 'white.main',
              },
            }}
            size="large"
            onClick={loginWithGoogle}
            startIcon={googleIcon}
          >
            <Typography color={'greyCustom.dark'}>login with google</Typography>
          </Button>
        )}

        <Button
          variant="outlined"
          fullWidth
          sx={{
            borderColor: 'greyCustom.light',
            backgroundColor: 'white.main',
            boxShadow: '1',
            '&:hover': {
              backgroundColor: 'white.main',
            },
          }}
          onClick={loginWithFb}
          startIcon={fbIcon}
        >
          <Typography color={'greyCustom.dark'}>login with facebook</Typography>
        </Button>
        <Divider sx={{ width: { xs: '95%', md: '45%' } }}>Or</Divider>
      </Stack>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{ width: width, mx: 'auto', mt: 2 }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: 1, mx: 'auto' }}
        >
          <TextField
            label="Email *"
            variant="outlined"
            name={'email'}
            type="email"
            fullWidth
            sx={{
              backgroundColor: 'white.main',
            }}
            inputProps={{ autoCapitalize: 'none' }}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            label="Password *"
            variant="outlined"
            name={'password'}
            type={'password'}
            fullWidth
            sx={{
              backgroundColor: 'white.main',
            }}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          <LoadingButton
            fullWidth
            variant={'contained'}
            type={'submit'}
            disabled={formik.isSubmitting}
          >
            Login
          </LoadingButton>

          <Box sx={{ width: 1 }}>
            <Typography variant={'subtitle2'}>
              Don't have an account yet?{' '}
            </Typography>
            <Typography
              variant={'subtitle2'}
              component={Link}
              color={'primary'}
              to={'/signup'}
              underline={'none'}
            >
              Sign up here.
            </Typography>
          </Box>
          {isWebView && (
            <Box sx={{ px: 2, pb: 3 }}>
              <Typography align="center">
                Looking for Google login? Open this page in your phone's web
                browser to access!
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
