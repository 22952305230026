import log from 'loglevel';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import RedoIcon from '@mui/icons-material/Redo';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParsePage } from '../../../hooks/ai-hooks';
import { ProductPageDetails } from '../../../types/page_details';
import { FetchedPage } from '../../../hooks/ai/analyze-page-fetch';
import { PaperAnalyze } from '../shared/PaperAnalyze';
import { useSegment } from '../../../contexts/segment-context';
import { useInIframe } from '../../../hooks/util/in-iframe-hook';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export enum PageInspectorStatus {
  IDLE = 'idle',
  RUNNING = 'running',
  COMPLETE = 'complete',
  ERROR = 'error',
}

type PageInspectorProps = {
  pageContent: FetchedPage;
  productPageDetails: ProductPageDetails;
  setProductPageDetails: (productPageDetails: ProductPageDetails) => void;
  setStatus: (newStatus: PageInspectorStatus) => void;
  manualMode?: boolean;
  disabled?: boolean;
  connectionId?: string;
  defaultExpanded?: boolean;
  url?: string;
};
export function PageInspector({
  pageContent,
  productPageDetails,
  setProductPageDetails,
  setStatus,
  manualMode = false,
  disabled = false,
  connectionId,
  defaultExpanded = true,
  url = null,
}: PageInspectorProps) {
  const inspectorQuery = useParsePage(
    pageContent?.markdown,
    !manualMode,
    connectionId,
    url,
  );
  const [inIframe] = useInIframe();
  const queryClient = useQueryClient();
  const segment = useSegment();
  const [expanded, setExpanded] = useState(defaultExpanded);

  const [isHovered, setIsHovered] = useState(false);
  const [internalDetails, setInternalDetails] = useState<ProductPageDetails>();
  const [helpOpen, setHelpOpen] = useState(false);

  useEffect(() => {
    if (manualMode) return;
    if (!inspectorQuery.data?.product) {
      setStatus(PageInspectorStatus.RUNNING);
      segment.track('Page Inspection Started', { url: pageContent.url });
      return;
    }
    log.debug('[I-PAV1] inspector query', inspectorQuery.data);
    setInternalDetails(inspectorQuery.data.product);
    segment.track('Page Inspection Completed', {
      url: pageContent.url,
      ...inspectorQuery.data.product,
    });
    setStatus(PageInspectorStatus.COMPLETE);
  }, [inspectorQuery.data, pageContent?.url, manualMode, segment, setStatus]);

  // report errors to the parent for handling
  useEffect(() => {
    if (inspectorQuery.isError) {
      setStatus(PageInspectorStatus.ERROR);
    }
  }, [inspectorQuery.isError, setStatus]);

  // If manual mode, enter some placeholder information
  useEffect(() => {
    if (manualMode) {
      queryClient.removeQueries({ queryKey: ['ai-page-inspector'] });
      setStatus(PageInspectorStatus.COMPLETE);
      setInternalDetails({
        brand: 'Unknown',
        title: 'Unknown',
        description: 'Unknown',
        category: 'Unknown',
        ingredients: ['Ingredient 1', 'Ingredient 2'],
      });
    }
  }, [manualMode, queryClient, setStatus]);

  const handleRefreshClick = async () => {
    setProductPageDetails(null);
    await queryClient.removeQueries({ queryKey: ['ai-page-inspector'] });
    await queryClient.removeQueries({ queryKey: ['analyze-product'] });
  };

  const handleAnalyzeClick = async () => {
    log.debug('Setting ProductPageDetails', internalDetails);
    await queryClient.removeQueries({ queryKey: ['analyze-product'] });
    // Hack, but forces the summary component to unload and refetch
    setProductPageDetails(null);
    setTimeout(() => setProductPageDetails(internalDetails), 10);
    setExpanded(false);
  };

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  // Don't render until we have something to show
  if (!internalDetails) return null;

  // We report errors to the parent for handling
  if (inspectorQuery.error) return null;

  const analysisComplete = !!productPageDetails;

  const pd = inspectorQuery.data;
  const sectionTitle =
    'Product Details' +
    (pd ? `: ${pd.product.brand}, ${pd.product.title}` : '');

  const isDisabled =
    disabled ||
    internalDetails?.ingredients === null ||
    internalDetails?.ingredients?.length === 0 ||
    internalDetails?.ingredients[0] === 'Ingredient 1' ||
    internalDetails?.ingredients[0] === '';

  return (
    <PaperAnalyze sx={{ p: { xs: 0.25, sm: 0.5 }, width: 1 }}>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        disableGutters
        elevation={0}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ m: 0 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 24,
              fontWeight: 500,
              // This is an ugly hack, but I can't get noWrap to work inside
              // an accordion with specifying a width like this.
              maxWidth: { xs: 275, sm: 500, md: 700 },
            }}
          >
            {sectionTitle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column">
            {!manualMode && !inIframe && (
              <Box
                sx={{
                  px: 1.5,
                  mb: 1,
                  width: '100%',
                  overflow: 'hidden',
                  display: 'flex',
                }}
              >
                <Typography
                  fontSize={14}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'auto',
                    textOverflow: 'clip',
                  }}
                >
                  {pageContent.url}
                </Typography>
              </Box>
            )}
            {!manualMode ? (
              <Paper
                elevation={0}
                sx={{
                  mx: 1,
                  mb: 3,
                  p: 1.5,
                  backgroundColor: 'greyCustom.light2',
                  cursor: 'pointer',
                }}
              >
                <Stack direction="row" spacing={1} alignItems={'center'}>
                  <LightbulbIcon color="primary" />
                  <Box onClick={() => setHelpOpen(true)}>
                    <Typography
                      color={'grey.800'}
                      sx={{ lineHeight: '19px', fontSize: 14, fontWeight: 400 }}
                    >
                      Click here if you don’t see the right information.
                    </Typography>
                  </Box>
                  <HelpDialog
                    open={helpOpen}
                    onClose={() => setHelpOpen(false)}
                  />
                </Stack>
              </Paper>
            ) : (
              <Typography sx={{ mb: 3 }}>
                Enter the product details below. The brand and product title are
                used to find reviews. The category and description help
                summarize ingredient information.
              </Typography>
            )}
            <ProductForm
              product={internalDetails}
              setProductPageDetails={setInternalDetails}
              manual={manualMode}
            />
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Typography sx={{ flexGrow: 1 }}>
                You can edit items above before continuing
              </Typography>
              {false && (
                <Button
                  variant="outlined"
                  onClick={handleRefreshClick}
                  startIcon={<RedoIcon />}
                  sx={{
                    whiteSpace: 'nowrap',
                    fontWeight: 600,
                    borderColor: analysisComplete
                      ? 'secondary.main'
                      : undefined,
                    color: analysisComplete ? 'secondary.main' : undefined,
                  }}
                >
                  Try Again
                </Button>
              )}
              <Button
                startIcon={
                  analysisComplete && (
                    <CheckCircleIcon sx={{ color: '#00FF00' }} />
                  )
                }
                disableElevation
                variant="contained"
                onClick={handleAnalyzeClick}
                disabled={isDisabled}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                  whiteSpace: { xs: 'normal', sm: 'nowrap' },
                  lineHeight: 1.3,
                  width: 180,
                  fontWeight: 600,
                  color: analysisComplete ? '#727287' : undefined,
                  backgroundColor: analysisComplete ? '#F0F0F2' : '',
                }}
              >
                {analysisComplete
                  ? isHovered
                    ? 'Re-analyze'
                    : 'Analyzed'
                  : 'Continue to Analysis'}
              </Button>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </PaperAnalyze>
  );
}

type ProductFormProps = {
  product: ProductPageDetails;
  setProductPageDetails: (productPageDetails: ProductPageDetails) => void;
  manual?: boolean;
  disabled?: boolean;
};
export const ProductForm = ({
  product,
  setProductPageDetails,
  manual = false,
  disabled = false,
}: ProductFormProps) => {
  // Don't really care about the formik submit value, just want to capture all
  // changes for the parent component
  const formik = useFormik({
    initialValues: {
      brand: product.brand || '',
      title: product.title || '',
      description: product.description || '',
      category: product.category || '',
      ingredients: product.ingredients?.join('\n') || '',
    },
    onSubmit: (values) => {},
  });

  const ingredientCount = product.ingredients?.length || 0;

  useEffect(() => {
    const ingredients = formik.values.ingredients.split('\n');
    const newProduct = {
      ...formik.values,
      ingredients,
    };
    setProductPageDetails(newProduct);
  }, [formik.values, setProductPageDetails]);

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" spacing={2}>
          <ProductTextField id="brand" formik={formik} disabled={disabled} />
          <ProductTextField
            id="title"
            label="Product Title"
            formik={formik}
            disabled={disabled}
          />
          <ProductTextField id="category" formik={formik} disabled={disabled} />
          <ProductTextField
            id="description"
            formik={formik}
            multiline
            maxRows={2.6}
            disabled={disabled}
          />
          <BreakNewLinesTextField
            id="ingredients"
            label={`${!manual ? ingredientCount + ' ' : ''} Ingredients`}
            helperText={!disabled && 'One ingredient per line'}
            formik={formik}
            multiline
            maxRows={8.6}
            disabled={disabled}
          />
        </Stack>
      </form>
    </Box>
  );
};

const ProductTextField = ({ id, label = '', formik, ...props }) => {
  return (
    <TextField
      fullWidth
      id={id}
      name={id}
      label={label || capitalizeFirstLetter(id)}
      value={formik.values[id]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[id] && Boolean(formik.errors[id])}
      {...props}
    />
  );
};

const BreakNewLinesTextField = ({ id, label = '', formik, ...props }) => {
  const decodeHTMLEntities = (text: string): string => {
    const entities = {
      '&#39;': "'",
      '&quot;': '"',
      '&lt;': '<',
      '&gt;': '>',
      '&amp;': '&',
    };
    return text.replace(/&#?\w+;/g, (match) => entities[match] || match);
  };

  const formatText = (text: string) => {
    const decodedText = decodeHTMLEntities(text);
    // Split the text by commas followed by a space, or semicolons, respecting text within parentheses
    const ingredients = decodedText.split(/,\s(?![^(]*\))|;(?![^(]*\))/);

    // Trim whitespace from each ingredient and join them with newlines
    const formattedIngredients = ingredients
      .map((ingredient) => ingredient.trim())
      .filter((ingredient) => ingredient !== '')
      .join('\n');

    return formattedIngredients;
  };

  const canReflow = formik.values[id] !== formatText(formik.values[id]);

  const handleClick = () => {
    formik.setFieldValue(id, formatText(formik.values[id]));
  };
  /*
  // This was causing too many errors. Also, the copy/paste functionality
  // runs into security issues when run inside an iframe.
  const handlePaste = async (e) => {
    e.preventDefault();
    const clipboardText = await navigator.clipboard.readText();
    const formattedText = formatText(clipboardText);

    const input = e.target;
    const { selectionStart, selectionEnd, value } = input;

    let newValue;
    let newCursorPosition;

    if (selectionStart !== selectionEnd) {
      // If there is a selection, replace it with the formatted text
      newValue =
        value.substring(0, selectionStart) +
        formattedText +
        value.substring(selectionEnd);
      newCursorPosition = selectionStart + formattedText.length;
    } else {
      // If no selection, insert formatted text at the cursor position
      newValue =
        value.substring(0, selectionStart) +
        formattedText +
        value.substring(selectionStart);
      newCursorPosition = selectionStart + formattedText.length;
    }

    formik.setFieldValue(id, newValue, false);

    // Wait until the state is updated, then set the cursor position
    setTimeout(() => {
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };*/

  return (
    <Box sx={{ position: 'relative' }}>
      {canReflow && (
        <Button
          variant="contained"
          size="small"
          sx={{ position: 'absolute', right: 10, top: 10, zIndex: 1000 }}
          onClick={handleClick}
        >
          Add line breaks
        </Button>
      )}
      <TextField
        fullWidth
        id={id}
        name={id}
        label={label || capitalizeFirstLetter(id)}
        value={formik.values[id]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched[id] && Boolean(formik.errors[id])}
        {...props}
      />
    </Box>
  );
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const HelpDialog = ({ open, onClose }) => {
  const [inIframe] = useInIframe();

  const webMessage = (
    <>
      <Typography sx={{ lineHeight: 1.3, fontSize: { xs: 14, sm: 16 } }}>
        Some sites only display certain information when the user clicks a
        particular button (ingredients are often hidden this way). The CountOn
        browser extension can be used to analyze those products because it uses
        the information displayed on the page, so you can display the
        ingredients before starting the analysis.
      </Typography>
      <br />
      <Typography sx={{ lineHeight: 1.3, fontSize: { xs: 14, sm: 16 } }}>
        Also, some pages block remote scaping. The CountOn browser extension
        will still work in those cases!
      </Typography>
    </>
  );

  const extMessage = (
    <>
      <Typography sx={{ lineHeight: 1.3, fontSize: { xs: 14, sm: 16 } }}>
        Most frequently an issue occurs when a site has hidden some information
        (like ingredients) in a separate tab or collapsable section. To fix
        this, refresh this page, find the ingredients and make sure the full
        list is visible then click "Analyze" again from the extension.
      </Typography>
      <br />
      <Typography sx={{ lineHeight: 1.3, fontSize: { xs: 14, sm: 16 } }}>
        If that still does not work you can always add the product and
        ingredient information manually by copying and pasting into the
        Ingredient field in the extension and then click "Continue to Analysis"
      </Typography>
    </>
  );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
      <DialogTitle id="alert-dialog-title" sx={{ lineHeight: 1.3 }}>
        Not seeing the right information?
      </DialogTitle>
      <DialogContent>{inIframe ? extMessage : webMessage}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
