import {
  useMutation,
  useQuery,
  useQueries,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { useSearchParams } from 'react-router-dom';
import { Tag } from 'src/types/account';
import { useAuth } from './use-auth';
import { logError } from 'src/lib/errorLib';
import { ExpertSearchResult } from '../types/search';

export function useAccountSearch(
  query = '',
  enabled = true,
  staleTime = Infinity
) {
  const { isAuthenticated } = useAuth();
  const accountSearch = useQuery<ExpertSearchResult, Error>(
    ['guest', 'accounts', 'search', query],
    () => {
      const cleanedQuery = query.toLowerCase().trim();
      return API.get(
        'trustable',
        `${
          isAuthenticated ? '' : '/guest'
        }/accounts/search?query=${cleanedQuery}`,
        {}
      );
    },
    {
      staleTime,
      enabled: enabled,
      onSuccess: (data) => log.debug('useAccountSearch data', data),
      onError: (error) => logError(error),
    }
  );

  return accountSearch;
}

interface TagCollection {
  [key: string]: Tag[];
}
/**
 * Returns the product_categories and values for your trusted accounts
 * (TODO: this is poorly named)
 *
 * they use
 * @param enabled
 * @param staleTime
 * @returns
 */
export function useGetTrustedAccounts(enabled = true, staleTime = Infinity) {
  // Get list of currently trusted accounts
  const trustedAccounts = useQuery<TagCollection, Error>(
    ['trusted-accounts', 'values'],
    () => {
      return API.get('trustable', '/trusted-account-tags', {});
    },
    {
      staleTime,
      enabled,
      onSuccess: (data) => log.debug('useGetTrustedAccounts data', data),
      onError: (error) => logError(error),
    }
  );

  return trustedAccounts;
}

export function useGetPublicProfile(accountName: string, showHidden?: boolean) {
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const showAll =
    showHidden || searchParams.get('account_status') === 'showall';

  const query = useQuery<any, Error>(
    ['p', accountName],
    () => {
      const apiStr =
        '/' +
        (isAuthenticated ? 'profile' : 'p') +
        '/' +
        accountName.toLowerCase() +
        (showAll ? '?account_status=showall' : '');
      return API.get('trustable', apiStr, {});
    },
    {
      staleTime: Infinity,
      enabled: !!accountName,
      onSuccess: (data) => log.trace('useGetPublicProfile', data),
      onError: (error) => logError(error),
    }
  );
  return query;
}

/**
 * Takes an array of account names and executes a query for each. (be
 * careful...)
 * @param account_names
 * @param showHidden
 * @returns
 */
export function useGetPublicProfiles(
  account_names: string[],
  showHidden?: boolean
) {
  const [searchParams] = useSearchParams();
  const showAll =
    showHidden || searchParams.get('account_status') === 'showall';

  const queries = useQueries({
    queries: account_names.map((account_name) => {
      return {
        queryKey: ['p', account_name],
        queryFn: () =>
          API.get(
            'trustable',
            `/p/${account_name.toLowerCase()}${
              showAll ? '?account_status=showall' : ''
            }`,
            {}
          ),
        staleTime: Infinity,
        enabled: account_names.length > 0 && !!account_names[0],
        onError: (error) => logError(error),
      };
    }),
  });
  return queries;
}

export function useCreateAccountMutation() {
  const mutation = useMutation(
    (body: any) => {
      return API.post('trustable', `/accounts`, { body });
    },
    {
      onSuccess: async (data) => log.info(data),
      onError: (error) => logError(error),
    }
  );
  return mutation;
}

export function useGetPublicProfileMutation() {
  const cache = useQueryClient();
  const mutation = useMutation(
    (user_name: string) => {
      return API.get('trustable', `/p/${user_name}`, {});
    },
    {
      onSuccess: async (data) => {},
      onError: (error) => {
        logError(error);
      },
      onSettled: () => {
        cache.invalidateQueries(['trust-recs']); // Won't be needed when recs move to ML
        cache.invalidateQueries(['trusted-users']);
      },
    }
  );
  return mutation;
}

type AccountUpdateInformation = {
  avatar_url?: string;
  display_name?: string;
  full_name?: string;
  short_desc?: string;
  long_desc?: string;
  homepage?: string;
  my_values?: string[];
  my_account_types?: string[];
};

export function useUpdateAccountMutation() {
  const cache = useQueryClient();
  const mutation = useMutation(
    (body: AccountUpdateInformation) => {
      return API.put('trustable', `/profile`, { body });
    },
    {
      onSuccess: async (data) => {
        cache.invalidateQueries(['users', 'user-profile']);
      },
      onError: (error) => {
        logError(error);
      },
    }
  );
  return mutation;
}

export function useGetAccountProductCategories(
  account_id: string,
  product_category: string
) {
  const query = useQuery<any, Error>(
    ['p', account_id, product_category],
    () =>
      API.get(
        'trustable',
        `/p/id/${account_id}/product_categories/${product_category}`,
        {}
      ),
    {
      staleTime: Infinity,
      enabled: !!account_id,
      onSuccess: (data) => log.trace('useGetAccountProductCategories', data),
      onError: (error) => logError(error),
    }
  );
  return query;
}

export function useGetAllAccountProductCategories(
  account_id: string,
  product_categories: []
) {
  const queries = useQueries({
    queries: product_categories.map((category) => {
      return {
        queryKey: ['p', account_id, category],
        queryFn: () =>
          API.get(
            'trustable',
            `/p/id/${account_id}/product_categories/${category}`,
            {}
          ),
        staleTime: Infinity,
        enabled: !!account_id,
        onError: (error) => logError(error),
      };
    }),
  });
  return queries;
}

export function useCreateUsernameMutation() {
  const mutation = useMutation(
    (username: string) => {
      return API.get('trustable', `/accounts/username/${username}`, {});
    },
    {
      onSuccess: async (data) => {},
      onError: (error) => {
        logError(error);
      },
    }
  );
  return mutation;
}

export function useUpdateExtInstalledMutation(account_id: string) {
  const mutation = useMutation(
    (body: any) => {
      return API.put('trustable', `/accounts/${account_id}/ext_installed`, {
        body,
      });
    },
    {
      onSuccess: async (data) => {},
      onError: (error) => {
        logError(error);
      },
    }
  );
  return mutation;
}

export function useUpdateSafariExtInstalledMutation(account_id: string) {
  const mutation = useMutation(
    (body: any) => {
      return API.post(
        'trustable',
        `/accounts/${account_id}/safari_ext_installed`,
        {
          body,
        }
      );
    },
    {
      onSuccess: async (data) => {},
      onError: (error) => {
        logError(error);
      },
    }
  );
  return mutation;
}
