import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { initSentry } from './lib/errorLib';
import config from './configs/aws-config';
import log from 'loglevel';
import { ExtensionStorage } from './lib/auth/ExtensionStorage';
import { IframeExtensionStorage } from './lib/auth/IframeExtensionStorage';
import { IframeExtensionStorageCS } from './lib/auth/IframeExtensionStorageCS';

/**
 * See https://www.npmjs.com/package/loglevel
 * This turns off logging in production, BUT, you can re-enable by going
 * to the console and entering log.setLevel('debug');
 * I set the default development level to 'info'. Keep using console.log
 * for random dev testing, but for messages you want to be able to examine
 * in prod, use log.debug(...)
 * log.setDefaultLevel lets you override the value in the console and
 * have it stay between page refreshes. Nice!
 */
//
log.setDefaultLevel(process.env.NODE_ENV === 'development' ? 'debug' : 'error');
window.log = log;
let signup_url = '';
let signout_url = '';

switch (config.cognito.STAGE) {
  case 'gamma':
    signup_url = 'https://d5zc6j9m86q05.cloudfront.net/signup/';
    signout_url = 'https://d5zc6j9m86q05.cloudfront.net/';
    break;
  case 'prod':
    signup_url = 'https://joincounton.com/signup/';
    signout_url = 'https://joincounton.com/';
    break;
  default:
    signup_url = 'https://localhost:3000/signup/';
    signout_url = 'https://localhost:3000/';
    break;
}

initSentry();

let inIframe = null;
try {
  inIframe = window.self !== window.top;
} catch (e) {
  inIframe = true;
}
log.debug('iFrame status: ', inIframe);

const path = window.location.pathname;
const sp = new URLSearchParams(window.location.search);
let myStorage = ExtensionStorage;
if (sp.get('auth') === 'cs') {
  myStorage = IframeExtensionStorageCS;
} else if (sp.get('auth') === 'local') {
  // Useful for debugging in local dev
  myStorage = ExtensionStorage;
} else if (path.startsWith('/ext/') && !path.startsWith('/ext/recommend')) {
  myStorage = IframeExtensionStorage;
}

// TODO: This is the better way, but will break old extension versions, so will
// update in the future
//const myStorage = inIframe ? IframeExtensionStorage : ExtensionStorage;

Amplify.configure({
  Auth: {
    storage: myStorage,
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: `${
        config.cognito.DOMAIN +
        '.auth.' +
        config.cognito.REGION +
        '.amazoncognito.com'
      }`,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: signup_url, // MAKE SURE THIS MATCHES AuthStack.js file
      redirectSignOut: signout_url, // MAKE SURE THIS MATCHES AuthStack.js file
      responseType: 'code',
    },
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: 'trustable',
        endpoint: config.trustableApiGateway.URL,
        region: config.trustableApiGateway.REGION,
      },
    ],
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

// Using React Query https://react-query.tanstack.com/quick-start
// Watch OUT! Always define this as high as possible. Leads to crazy, erratic behavior otherwise
// as various queryClients might be floating around making debugging super frustrating
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <App />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
