import { Box, Button, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type NextStepBarProps = {
  enabled: boolean;
  onClick: () => void;
};

export const NextStepBar = ({ enabled, onClick }: NextStepBarProps) => {
  return (
    <Box
      sx={{
        zIndex: 1000,
        width: '100vw',
        position: 'fixed',
        bottom: 0,
        left: 0,
        px: 3,
        height: '60px',
        backgroundColor: 'white !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Button
        sx={{
          height: '40px',
          width: { xs: '100%', md: '200px' },
        }}
        onClick={onClick}
        disabled={!enabled}
        variant="contained"
      >
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}
        >
          Next <KeyboardArrowRightIcon />
        </Typography>
      </Button>
    </Box>
  );
};
