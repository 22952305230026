import { Button, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';

type NewAnalysisButtonProps = {
  account_names?: string[];
};
export default function NewAnalysisButton({
  account_names,
}: NewAnalysisButtonProps) {
  const location = useLocation();

  const url = `/analyze-page${
    account_names ? '?accounts=' + account_names.join(',') : location.search
  }`;

  return (
    <Button
      component={Link}
      target="_blank"
      to={url}
      startIcon={<AddCircleIcon />}
      variant="contained"
    >
      <Typography fontWeight={600}>Analyze another product</Typography>
    </Button>
  );
}
