import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import SectionContainer from '../../components/SectionContainer';
import { useEffect, useState } from 'react';
import { ProductForm } from './components/PageInspector';
import { ProductPageDetails } from '../../types/page_details';
import { useParams } from 'react-router-dom';
import { useGetSavedProductAnalysis } from '../../hooks/ai/get-saved-product-analysis-hook';
import { PaperAnalyze } from './shared/PaperAnalyze';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NewAnalysisButton from './shared/NewProductAnalysisButton';
import { ProductSummaryWidget } from './components/ProductSummaryWidget';
import SavedAnalysisDrawer from './components/SavedAnalysisDrawer';
import ListAltIcon from '@mui/icons-material/ListAlt';

export default function ViewSavedAnalysis() {
  const { analysis_id } = useParams();
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    document.title = 'CountOn - Analysis Results';
  }, []);

  const query = useGetSavedProductAnalysis(analysis_id);

  if (query.isLoading) {
    return <LoadingScreen />;
  }
  if (query.isError) {
    return <ErrorScreen />;
  }

  const experts = query.data.account_names || [];

  return (
    <SavedAnalysisDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <ViewContainer>
        <Stack direction="row" sx={{ pb: { xs: 0, sm: 1.5 } }}>
          <Typography fontSize={{ xs: 14, sm: 19 }}>
            These AI summaries are in beta and may contain mistakes.
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          sx={{ mt: { xs: 0, sm: 2 }, width: 1, maxWidth: 1000 }}
        >
          <Button
            sx={{ alignSelf: 'flex-start' }}
            startIcon={<ListAltIcon />}
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            Your Previous Analyses
          </Button>
          <NewAnalysisButton account_names={experts} />
        </Stack>
        <AnalysisInputs input={query.data.input} url={query.data.url} />
        <ProductSummaryWidget analysis={query.data.result} />
      </ViewContainer>
    </SavedAnalysisDrawer>
  );
}

type AnalysisInputsProps = {
  input: ProductPageDetails;
  url: string;
};
const AnalysisInputs = ({ input, url }: AnalysisInputsProps) => {
  const [expanded, setExpanded] = useState(false);
  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <PaperAnalyze sx={{ p: { xs: 0.25, sm: 0.5 }, width: 1 }}>
      {input ? (
        <Accordion
          expanded={expanded}
          onChange={handleAccordionChange}
          disableGutters
          elevation={0}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ m: 0 }}>
            <Typography
              noWrap
              sx={{
                fontSize: 24,
                fontWeight: 500,
                // This is an ugly hack, but I can't get noWrap to work inside
                // an accordion with specifying a width like this.
                maxWidth: { xs: 275, sm: 500, md: 700 },
              }}
            >
              {input?.brand || 'Unknown Brand'} {input?.title || ''}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                px: 1.5,
                mb: 2,
                width: '100%',
                overflow: 'hidden',
                display: 'flex',
              }}
            >
              <Typography
                fontSize={14}
                color={'grey'}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'auto',
                  textOverflow: 'clip',
                }}
              >
                {url}
              </Typography>
            </Box>
            <ProductForm
              product={input}
              setProductPageDetails={() => {}}
              disabled={true}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <Typography>No input data found</Typography>
      )}
    </PaperAnalyze>
  );
};

const LoadingScreen = () => {
  return (
    <ViewContainer>
      <PaperAnalyze sx={{ p: 4, width: 1, textAlign: 'center' }}>
        <Typography fontSize={22} fontWeight={600}>
          Loading...
        </Typography>
      </PaperAnalyze>
    </ViewContainer>
  );
};

const ErrorScreen = () => {
  return (
    <ViewContainer>
      <PaperAnalyze sx={{ p: 4, width: 1, textAlign: 'center' }}>
        <Typography fontSize={22} fontWeight={600}>
          Sorry! Could not retrieve that analysis.
        </Typography>
      </PaperAnalyze>
    </ViewContainer>
  );
};

const ViewContainer = ({ children }) => {
  return (
    <SectionContainer
      backgroundColor="transparent"
      minHeight={1000}
      sx={{ py: 5 }}
    >
      <Stack width={1} spacing={1} direction="column" alignItems={'center'}>
        {children}
      </Stack>
    </SectionContainer>
  );
};
