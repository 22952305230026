import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '16px',
  borderRadius: '8px',
  backgroundColor: '#F0F0F2',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F0F0F2',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1CD98D' : '#1CD98D',
  },
}));

export const BackProgressBar = ({
  progress,
  onClick = () => {},
  showPrev = true,
  enabled = true,
}) => {
  return (
    <Box
      sx={{
        zIndex: 1000,
        position: 'fixed',
        width: '100vw',
        left: 0,
        top: 0,
        height: '48px',
        px: 3,
        backgroundColor: 'white !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box
        sx={{
          height: '48px',
          maxWidth: { xs: '100%', md: '800px' },
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: '1',
        }}
      >
        {showPrev && (
          <Button disabled={!enabled} variant="text" onClick={onClick}>
            <KeyboardArrowLeft />{' '}
            <Typography sx={{ mr: 2, fontWeight: 600 }}>Back</Typography>
          </Button>
        )}
        <StyledLinearProgress
          sx={{
            width: '100%',
          }}
          variant="determinate"
          value={progress}
        />
      </Box>
    </Box>
  );
};
