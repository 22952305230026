import { useState, useEffect } from 'react';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { OnboardingStep } from '../../../types/user';
import { useAuth } from 'src/hooks/use-auth';
import { useUpdateUserOnboardingMutation } from 'src/hooks/user-hooks';
import { toast } from 'react-hot-toast';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { NextStepBar } from '../components/NextStepBar';
import { useUpdateAccountMutation } from 'src/hooks/account-hooks';
import { useNavigate } from 'react-router-dom';
import { BackProgressBar } from '../components/BackProgressBar';
import CheckboxSVG from './Checkbox.svg';
import { EXPERT_TYPES_MAP } from '../types';
import { useGetProfile } from 'src/hooks/user-hooks';
import { useSegment } from 'src/contexts/segment-context';
import { useReferrer } from 'src/lib/hooksLib';

const EXPERT_TYPES_FOR_GIMME_REFERRER = [
  'certifier',
  'creator',
  'watchdog_agency',
];

const ExpertTypesPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isGoingBack, setIsGoingBack] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const segment = useSegment();
  const userMutation = useUpdateUserOnboardingMutation();
  const profileQuery = useGetProfile();
  const accoutUpdateMutation = useUpdateAccountMutation();
  const gb = useGrowthBook();
  const valueMap = gb.getFeatureValue(
    'onboarding-expert-type-map',
    EXPERT_TYPES_MAP
  );
  const [referrerAccountName] = useReferrer();

  const [selectedValues, setSelectedValues] = useState(
    user.active_account?.my_account_types ?? []
  );

  const [initialValues, setInitialValues] = useState(
    user.active_account?.my_account_types ?? []
  );

  useEffect(() => {
    if (profileQuery.data) {
      const myAccountTypes =
        profileQuery.data.active_account?.my_account_types ?? [];
      const accountTypesFromReferrer =
        referrerAccountName === 'gimmethegoodstuff'
          ? EXPERT_TYPES_FOR_GIMME_REFERRER
          : [];
      const allAccountTypes = [...myAccountTypes, ...accountTypesFromReferrer];
      const uniqueAccountTypesSet = new Set(allAccountTypes);
      const uniqueAccountTypesArray = Array.from(uniqueAccountTypesSet);

      setSelectedValues(uniqueAccountTypesArray);
      setInitialValues(myAccountTypes);
    }
  }, [profileQuery.data, referrerAccountName]);

  const saveType = (type) => {
    setSelectedValues((types) => {
      if (types.includes(type)) {
        return types;
      }

      return [...types, type];
    });
  };

  const removeType = (type) => {
    setSelectedValues((types) => {
      return types.filter((t) => t !== type);
    });
  };

  const reportAnalytics = () => {
    segment.track('Expert Types Selected', {
      context: 'onboarding',
      values: selectedValues,
      path: '/onboarding/expert-types',
    });

    const addedTypes = selectedValues.filter((v) => !initialValues.includes(v));

    if (addedTypes.length > 0) {
      segment.track('Expert Types Added', {
        context: 'onboarding',
        values: addedTypes,
        path: '/onboarding/expert-types',
      });
    }

    const removedTypes = initialValues.filter(
      (v) => !selectedValues.includes(v)
    );

    if (removedTypes.length > 0) {
      segment.track('Expert Types Removed', {
        context: 'onboarding',
        values: removedTypes,
        path: '/onboarding/expert-types',
      });
    }

    segment.identify(user.user_id, {
      my_expert_types: selectedValues,
    });
  };

  const goToNextStep = async () => {
    setIsLoading(true);
    try {
      user.onboarding = OnboardingStep.EXPERTS;
      await userMutation.mutateAsync(user);
      await accoutUpdateMutation.mutateAsync({
        my_account_types: selectedValues,
      });

      reportAnalytics();

      navigate('/onboarding/experts');
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  const goToPrevStep = async () => {
    setIsGoingBack(true);
    try {
      user.onboarding = OnboardingStep.VALUES;
      // Save the account_types so you store them when coming back here
      await userMutation.mutateAsync(user);
      await accoutUpdateMutation.mutateAsync({
        my_account_types: selectedValues,
      });

      reportAnalytics();

      navigate('/onboarding/values');
      setIsGoingBack(false);
    } catch (err) {
      console.error(err);
      toast.error(err.message);
      setIsGoingBack(false);
    }
  };

  return (
    <Box>
      <BackProgressBar
        progress={66}
        showPrev={true}
        onClick={goToPrevStep}
        enabled={!isGoingBack}
      />
      <Stack
        direction="column"
        sx={{
          maxWidth: 800,
          margin: '0 auto',
          py: 8,
          px: { xs: 3, md: 0 },
          height: '100%',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            mb: 2,
            xs: { textAlign: 'left' },
            md: { textAlign: 'center' },
          }}
        >
          What type of experts do you trust?
        </Typography>
        <Typography
          sx={{
            xs: { textAlign: 'left' },
            md: { textAlign: 'center' },
            mb: 2,
          }}
        >
          Select as many you like. You can always change this later.
        </Typography>

        {Object.keys(valueMap).map((key) => {
          const value = valueMap[key];
          const selected = selectedValues.includes(key);

          return (
            value && (
              <TypeBlock
                key={key}
                title={value.title}
                description={value.description}
                selected={selected}
                type={key}
                onClick={() => {
                  selected ? removeType(key) : saveType(key);
                }}
              />
            )
          );
        })}
      </Stack>
      <NextStepBar
        enabled={selectedValues.length > 0 && !isLoading}
        onClick={goToNextStep}
      />
    </Box>
  );
};
export default ExpertTypesPage;

const TypeBlock = ({ title, description, type, selected, onClick }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="start"
      sx={{
        border: '2px solid',
        borderColor: selected ? 'primary.main' : 'greyCustom.light',
        backgroundColor: selected ? 'primary.light2' : 'white.main',
        borderRadius: '8px',
        mb: 2,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Box>
        <Checkbox
          sx={{
            py: '2px',
            '& .MuiSvgIcon-root': {
              fontSize: 42,
              py: 0,
            },
          }}
          icon={<Unchecked />}
          checked={selected}
          inputProps={{ 'aria-label': 'controlled' }}
        ></Checkbox>
      </Box>
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography sx={{ color: 'greyCustom.main', pb: 1 }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const Unchecked = () => {
  return (
    <Box sx={{ paddingTop: '4px', paddingLeft: '4px' }}>
      <img src={CheckboxSVG} width="32" height="32" alt="Unchecked Checkbox" />
    </Box>
  );
};
