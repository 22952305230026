import { useEffect, useState } from 'react';
import log from 'loglevel';
import { useSearchParams } from 'react-router-dom';
import { useFeatureValue } from '@growthbook/growthbook-react';

const REFERRER_KEY = 'referrer';
const DEFAULT_REFERRER_GB_KEY = 'default-referrer';

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
}

/**
 * Provide a value to set the local storage. No value returns either the value
 * in the "r" search parameter or the locally stored value. Not sure if this is
 * the right way to do this.
 *
 * Note: Don't count on this like a shared global state. If this hook in one
 * component updates the local storage, the hook in another component using this
 * won't update. However, it should always correctly return the URL "r" search
 * parameter.
 * @param {} referrerAccountName
 * @returns
 */
export function useReferrer(): [v: string, f: (val: string) => void] {
  const [searchParams] = useSearchParams();
  const referrerSearchParam = searchParams.get('r');
  const defaultReferrer = useFeatureValue(DEFAULT_REFERRER_GB_KEY, null);
  const [referrer, setReferrer] = useLocalStorage<string | null>(
    REFERRER_KEY,
    referrerSearchParam ? referrerSearchParam : null
  );

  // If a 'r' search parameter exists, always set it as the current referrer
  useEffect(() => {
    if (!referrerSearchParam) return;
    setReferrer(referrerSearchParam);
  }, [referrerSearchParam, setReferrer]);

  // If there's no referrer (either saved in LocalStorage or in the URL), but
  // there is a default referrer, set it
  useEffect(() => {
    if (!referrer && !referrerSearchParam && defaultReferrer) {
      log.debug('Setting default referrer', defaultReferrer);
      setReferrer(defaultReferrer);
    }
  }, [referrer, defaultReferrer, referrerSearchParam, setReferrer]);

  return [referrer, setReferrer];
}

const getStorageValue = (key, defaultValue) => {
  // getting stored value
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    log.error(error.message);
    return defaultValue;
  }
};

// Watch out, if key starts as null it screws everything up
export const useLocalStorage = <T>(
  key: string,
  defaultValue: T
): [v: T, f: (val: T) => void] => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  const setStorageValue = (valueToStore: T) => {
    try {
      setValue(valueToStore);
      log.info(`Saving "${valueToStore}" as "${key}" in local storage`);
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      log.error(error);
    }
  };

  return [value, setStorageValue];
};
