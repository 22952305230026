import { Typography, Box, IconButton } from '@mui/material';
import SectionContainer from 'src/components/SectionContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPublicProfile } from 'src/hooks/account-hooks';
import { LoadingSpinner } from 'src/components/loading-spinner';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { gtm } from 'src/lib/gtm';
import { useSegment } from 'src/contexts/segment-context';
import BigChip from '../../shared/BigChip';

/**
 *
 * @param {*} param0
 * @returns
 */
export default function Values() {
  const { account_name } = useParams();
  const userProfileQuery = useGetPublicProfile(account_name);
  const navigate = useNavigate();
  const segment = useSegment();

  if (userProfileQuery.isLoading) return <LoadingSpinner />;
  if (userProfileQuery.isError)
    return (
      <SectionContainer>
        Error: {userProfileQuery.error.message}
      </SectionContainer>
    );

  const account = userProfileQuery.data;
  const values = account.values;

  if (!account) {
    return (
      <SectionContainer>
        <Typography>Sorry, there is no account with that ID.</Typography>
      </SectionContainer>
    );
  }

  const handleClick = (tag: string) => {
    gtm.push({
      event: 'custom_click',
      category: 'filter_values',
      title: tag,
      id: tag,
    });
    segment.track('Filter by Values', {
      category: 'all values picker',
      title: tag,
      id: tag,
    });
    navigate(`/p/${account_name}?${formatUrlParams([tag])}`);
  };

  return (
    <Box>
      <SectionContainer>
        <IconButton
          aria-label="back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon color="primary" style={{ fontSize: 40 }} />
        </IconButton>
      </SectionContainer>

      <SectionContainer
        backgroundColor="pastel.light"
        minHeight="inherit"
        sx={{ py: { xs: 3, sm: 3 } }}
      >
        <Box sx={{ display: 'flex', flexFlow: 'row wrap', gap: 1 }}>
          {values.map((value) => (
            <BigChip key={value.tag} onClick={() => handleClick(value.tag)}>
              {formatLabel(value.tag)}
            </BigChip>
          ))}
        </Box>
      </SectionContainer>
    </Box>
  );
}

//val%5B0%5D=%23natural&val%5B1%5D=%23non-toxic
const formatUrlParams = (values: string[]) => {
  return values
    .map((v, i) => `val%5B${i}%5D=${encodeURIComponent(v)}`)
    .join('&');
};

function formatLabel(str: string) {
  return capitalizeWords(removeLeadingHash(str));
}

function removeLeadingHash(str: string) {
  return str.charAt(0) === '#' ? str.slice(1) : str;
}

function capitalizeWords(str: string) {
  let words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
}
