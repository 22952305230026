import {
  Avatar,
  Box,
  Button,
  Input,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import babyListUrl from 'src/assets/logos/babylist_logo.png';
import climateNeutralUrl from 'src/assets/logos/climate_neutral_logo.png';
import annemarieUrl from 'src/assets/logos/annemarie_logo.png';
import { useGetPublicProfile } from 'src/hooks/account-hooks';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import log from 'loglevel';
import { gtm } from 'src/lib/gtm';
import { useSegment } from 'src/contexts/segment-context';
import { useReferrer } from 'src/lib/hooksLib';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';

export default function FindYourExpert() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Stack
      direction={{ xs: 'column-reverse', md: 'row' }}
      justifyContent="center"
      alignItems="center"
      spacing={{ xs: '0px', md: '120px' }}
      sx={{ my: { xs: '80px', md: '120px' }, px: { xs: 2, md: 2 } }}
    >
      <SearchSide />
      <AvatarSide avatarSize={isMd ? 245 : 140} />
    </Stack>
  );
}

const SearchSide = () => {
  const queryState = useState('');
  const navigate = useNavigate();
  return (
    <Stack spacing={3} maxWidth={400} alignItems="flex-start">
      <Typography
        align="center"
        color="#18173A"
        fontSize="40px"
        fontWeight="600"
      >
        Find your expert
      </Typography>
      <SearchBar queryState={queryState} placeholder="Type a name" />
      <Button
        variant="contained"
        color="secondary"
        endIcon={<KeyboardArrowRightIcon />}
        sx={{ fontSize: '17px', fontWeight: 600 }}
        onClick={() => navigate('/signup')}
      >
        Browse all experts
      </Button>
    </Stack>
  );
};

const AvatarSide = ({ avatarSize = 245 }) => {
  const [referrerAccountName] = useReferrer();
  const referrerQuery = useGetPublicProfile(referrerAccountName);

  const baselineSx = {
    width: avatarSize,
    height: avatarSize,
    position: 'absolute',
  };

  const defaultAvatarUrl = annemarieUrl;
  const avatarUrl = referrerAccountName
    ? referrerQuery?.data?.avatar_url
    : defaultAvatarUrl;

  // Hard coding things is wrong, but I couldn't figure out a better way
  // do this given the time allowed
  const boxSize = { xs: 260, md: 450 };
  const tmp = {
    item1: {
      top: { xs: 50, md: 80 },
      left: { xs: 0, md: 0 },
      zIndex: 0,
    },
    item2: {
      top: { xs: 0, md: 0 },
      left: { xs: 115, md: 190 },
      zIndex: 1,
    },
    item3: {
      top: { xs: 115, md: 200 },
      left: { xs: 100, md: 167 },
      zIndex: 2,
    },
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: boxSize,
        height: boxSize,
      }}
    >
      <Avatar src={climateNeutralUrl} sx={{ ...baselineSx, ...tmp.item1 }} />
      <Avatar src={babyListUrl} sx={{ ...baselineSx, ...tmp.item2 }} />
      {!!avatarUrl && (
        <Avatar src={avatarUrl} sx={{ ...baselineSx, ...tmp.item3 }} />
      )}
    </Box>
  );
};

export const SearchBar = ({
  queryState,
  autoFocus = false,
  placeholder = 'Product, brand, or value',
}) => {
  const [query, setQuery] = queryState;
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const segment = useSegment();

  useEffect(() => {
    setSearchText(query);
  }, [query]);

  const handleSubmit = () => {
    setQuery(searchText);
    log.info('SearchBar searchText', searchText);
    gtm.push({
      event: 'person-search',
      search_term: searchText,
      search_location: 'searchbar',
    });
    segment.track('Person Search', {
      searchTerm: searchText,
      searchLocation: 'searchbar_expert_website',
      path: location.pathname,
    });
    navigate('/signup');
  };

  const clearSearch = () => {
    setSearchText('');
    setQuery('');
    log.trace('SearchBar cleared search');
  };

  return (
    <Input
      fullWidth
      onKeyDown={(ev) => {
        if (ev.key === 'Enter') {
          handleSubmit();
        }
      }}
      sx={{
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        py: 1,
        px: 1,
        border: 2,
        borderColor: 'greyCustom.light',
        fontSize: 18,
        fontWeight: 600,
        name: 'counton-search-1',
        autocomplete: 'do-not-autofill-counton',
        autocorrect: 'off',
        autocapitalize: 'none',
        spellCheck: 'false',
      }}
      autoFocus={autoFocus}
      placeholder={placeholder}
      aria-label="search query"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      disableUnderline={true}
      inputProps={{}}
      startAdornment={
        <InputAdornment position="end">
          <SearchIcon
            sx={{ color: 'black', fontSize: 30, mr: 1 }}
            onClick={handleSubmit}
          />
        </InputAdornment>
      }
      endAdornment={
        searchText && (
          <InputAdornment position="end" onClick={clearSearch}>
            <CloseIcon sx={{ color: 'black', fontSize: 30 }} />
          </InputAdornment>
        )
      }
    />
  );
};
