import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { User } from '../types/user';
import { logError } from 'src/lib/errorLib';

export function useGetProfile() {
  const query = useQuery(
    ['user-profile'],
    () => API.get('trustable', '/profile', {}),
    {
      onSuccess: async (profile) => {},
      onError: (error) => {
        logError(error);
      },
    }
  );
  return { ...query };
}

interface CreateUserVariables {
  user: User;
  referrer?: string;
  preview?: string;
  display_name?: string;
}

export function useCreateUserMutation() {
  const cache = useQueryClient();
  const mutation = useMutation(
    (createUserVar: CreateUserVariables) => {
      return API.post('trustable', '/users', {
        body: {
          user: createUserVar.user,
          referrer: createUserVar.referrer || null,
          preview: createUserVar.preview || null,
          display_name: createUserVar.display_name || '',
        },
      });
    },
    {
      onSuccess: async (data) => {},
      onError: (error) => {
        logError(error);
      },
      onSettled: () => {
        cache.invalidateQueries(['users']);
      },
    }
  );
  return mutation;
}

export function useGetUserMutation() {
  const cache = useQueryClient();
  const mutation = useMutation(
    () => {
      return API.get('trustable', '/profile', {});
    },
    {
      onSuccess: async (data) => {},
      onError: (error) => {
        logError(error);
      },
      onSettled: () => {
        cache.invalidateQueries(['users']);
      },
    }
  );
  return mutation;
}

export function useUpdateUserOnboardingMutation() {
  const cache = useQueryClient();
  const mutation = useMutation(
    (user: User) => {
      return API.put('trustable', '/user/update-onboarding', { body: user });
    },
    {
      onSuccess: async (data) => {},
      onError: (error) => {
        logError(error);
      },
      onSettled: () => {
        cache.invalidateQueries(['users']);
      },
    }
  );
  return mutation;
}
