import { useQuery, useMutation } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from 'src/lib/errorLib';
import { useSegment } from 'src/contexts/segment-context';
import { useAuth } from './use-auth';

export function useGetRefferer(referrer) {
  const query = useQuery(
    ['p', referrer],
    () => API.get('trustable', `/p/${referrer.toLowerCase()}`, {}),
    {
      staleTime: Infinity,
      enabled: !!referrer,
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  return { ...query };
}

// Capturing the referrer happens here for social signups, but in
// verify.tsx for username+password signups
export function useGetReferrerMutation(referrer: string) {
  const { user } = useAuth();
  const segment = useSegment();
  const mutation = useMutation(
    () => {
      return API.get('trustable', `/p/${referrer.toLowerCase()}`, {});
    },
    {
      onSuccess: () => {
        log.debug('Referrer was added.');
        segment.track('Referrer Added', {
          referrer_account_name: referrer,
          debug_loc: 'useGetReferrerMutation',
        });
        if (user?.user_id)
          segment.identify(user.user_id, {
            referrer_account_name: referrer,
          });
        else log.error('No user object after successful referrer mutation!');
      },
      onError: (error) => logError(error),
    }
  );
  return mutation;
}
